import { isMobile } from './../../../common/functions';
import PrkSlider from './../prk-slider'
import Product from './product';
import PrkProductSliderDataProvider from './../../../data-providers/prk-product-slider-data-provider';
import { parentElements } from '../../../utils/parentElements';
import { logError } from '../../../utils/logError';
import unescapeHTML from '../../../utils/unescapeHTML';

export class PrkProductSlider extends PrkSlider {
    /**
     * @inheritdoc
     */
    constructor (container, options) {
        // By default use PrkProductSlider::renderRrSlide method to render slides
        const breakpoints = {
            720:  { slidesPerView: 4, slidesPerGroup: 4, spaceBetween: 12 },
            940:  { slidesPerView: 5, slidesPerGroup: 5, spaceBetween: 20 },
            1140: { slidesPerView: 6, slidesPerGroup: 6, spaceBetween: 20 },
        };

        // Fix for recommended products popup when adding a product to user's cart.
        // We don't want this breakpoint on mobiles so that more slides are shown there and
        // behaivior is consistent up to ipad screen size
        if (!isMobile())
            breakpoints[505] = { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 12 };

        super(container, Object.assign({}, {
            dataProvider: new PrkProductSliderDataProvider(),
            slidesToLoad: 12,
            swiperOptions: {
                loop: !isMobile(),
                spaceBetween: 0, // When slidesPerView: 'auto', spaceBetween is incorrectly calculated
                slidesPerView: 'auto',
                breakpoints,
            }
        }, options));

        this.eventListeners = this.eventListeners || {};
        this.eventListeners.onBuyButtonClick = this.onBuyButtonClick.bind(this);
        this.eventListeners.onLinkClick = this.onLinkClick.bind(this);
        this.eventListeners.onProductSliderArrowClick = this.onProductSliderArrowClick.bind(this);
        this.eventListeners.onSliderGetIntoViewport = this.onSliderGetIntoViewport.bind(this);
        this.eventListeners.onLikeButtonClick = this.onLikeButtonClick.bind(this);

        this.title = unescapeHTML(this.container.dataset.title);

        this.ready
            .then(() => this.initProductSliderSpecificLogic())
            .catch(() => null);
    }

    /**
     * Returns array of params that
     * can be sent via query parameters
     * @returns {string[]}
     */
    get supportedQueryParams () {
        return ['type', 'categoryIds', 'productIds', 'searchPhrase', 'collectionName'];
    }

    /**
     * Initializes parameters
     * @private
     * @returns {void}
     */
    initParams () {
        const dataset = this.container.dataset;

        this._params = {
            catalogDomain: dataset.catalogDomain,
        };

        // Parameter is optional, but default to false
        this._params.changeTypeOnEmptyApiResponse = 'true' === dataset.changeTypeOnEmptyApiResponse;

        // Note that type and page are required to send RR events
        // and type is required for lazy loading new slides on scrolling
        if (dataset.type)
            this._params.type = dataset.type;

        if (dataset.page)
            this._params.page = dataset.page;

        if (dataset.productIds)
            this._params.productIds = dataset.productIds;

        if (dataset.categoryIds)
            this._params.categoryIds = dataset.categoryIds;

        if (dataset.searchPhrase)
            this._params.searchPhrase = unescapeHTML(dataset.searchPhrase);

        if (dataset.collectionName)
            this._params.collectionName = unescapeHTML(dataset.collectionName);

        // Use hostname as catalogDomain if not provided
        if (!this._params.catalogDomain) {
            logError(new Error('Empty parameter catalogDomain in PrkProductSlider. Using window.location.hostname instead'));
            this._params.catalogDomain = window.location.hostname;
        }
    }

    /**
     * Returns slilder parameters
     * @returns {{
     *  type: string,
     *  page: string,
     *  catalogDomain: string,
     *  changeTypeOnEmptyApiResponse: boolean,
     *  productIds?: number,
     *  categoryIds?: number,
     *  searchPhrase?: string,
     *  collectionName?: string,
     * }}
     */
    get params () {
        // If params were not initialized, initialize
        if (!this._params)
            this.initParams();

        return this._params;
    }

    /**
     * Method to render RR slide
     * @override
     * @protected
     * @param {Product} product
     * @returns {HTMLElement}
     */
    renderSlide (product) {
        const slide = document.createElement('div');
        slide.className = 'swiper-slide prk-slider__slide prk-product-slider__slide';

        slide.innerHTML = `
            <div
                class="product c-product c-product--recommendation prk-product-slider__product"
                data-product-id="${product.productId}"
            >
                <div class="c-product__header">
                    <button class="btn icon-btn add-to-fav js-add-to-fav product_${product.productId} icon add-to-fav-icon"
                        data-attr_prod-id="${product.productId}"
                        data-balloon-pos="down" 
                        aria-label="Добавить товар в избранное"
                    ></button>
                </div>
                <a
                    class="aspect-ratio c-product__image"
                    href="${product.url}"
                    title="${product.title}"
                >
                    <span class="aspect-ratio__content">
                        ${
                            product.discountPrice < product.basePrice
                                ? `<span class="discount-badge">-${Math.round(((product.basePrice - product.discountPrice) / product.basePrice) * 100)}%</span>`
                                : ''
                        }
                        <img
                            data-src="${product.previewPictureUrl}"
                            class="swiper-lazy"
                            width="232"
                            height="232"
                            alt="${product.title}"
                        >
                        <span class="swiper-lazy-preloader"></span>
                    </span>
                    <span class="c-product__badges">
                        ${
                            product.isPickupOnly
                                ? `<span class="product-badge product-badge--pickup-only">Только самовывоз</span>`
                                : ''
                        }
                        ${
                            !product.isPickupOnly && product.isOurBrand
                                ? `<span class="product-badge product-badge--best-price">Лучшая цена</span>` 
                                : ''
                        }
                        ${
                            product.isPremiumProduct
                                ? '<span class="product-badge product-badge--premium-product">Премиум товар</span>'
                                : ''
                        }
                    </span>
                </a>
                <a
                    class="c-product__title"
                    href="${product.url}"
                    title="${product.title}"
                >
                    ${product.title}
                </a>
                <div class="c-product__buy-block">
                    <div class="c-product__price-block">
                        <div class="c-product__price">${product.formattedPrice}</div>
                        ${
                            product.discountPrice < product.basePrice
                                ? `<div class="c-product__price c-product__price--old">${product.formattedOldPrice}</div>`
                                : ''
                        }
                    </div>
                    <div class="c-product__actions">
                        <button
                            class="btn-success quick-view product_${product.productId} c-product__buy-button"
                            data-attr_prod-id="${product.productId}"
                            title="В корзину"
                        >
                            <svg class="pi pi-cart"><use xlink:href="#cart"></use></svg>
                            В корзину
                        </button>
                    </div>
                </div>
            </div>
        `;

        // Attach event handlers directly to slide or its children
        // because swiper events may be hard to handle properly:
        // for example: event.preventDefault() might on links might not work
        this.attachHandlersToSlide(slide);
        return slide;
    }

    /**
     * Attaches event listeners to slide
     * @private
     * @param {HTMLElement} slide
     * @returns {void}
     */
    attachHandlersToSlide (slide) {
        for (const link of [...slide.querySelectorAll('[href]')])
            link.addEventListener('click', this.eventListeners.onLinkClick);

        for (const btn of [...slide.querySelectorAll('.js-add-to-fav')])
            btn.addEventListener('click', this.eventListeners.onLikeButtonClick);

        $(slide).on('click', '.c-product__buy-button, .js-cart-quantity__btn--increment', event => this.eventListeners.onBuyButtonClick(event));
    }

    /**
     * Detaches event listeners from slide
     * @private
     * @param {HTMLElement} slide
     * @returns {void}
     */
    detachHandlersFromSlide (slide) {
        for (const link of [...slide.querySelectorAll('[href]')])
            link.removeEventListener('click', this.eventListeners.onLinkClick);

        for (const btn of [...slide.querySelectorAll('.js-add-to-fav')])
            btn.removeEventListener('click', this.eventListeners.onLikeButtonClick);

        $(slide).off('click', '.c-product__buy-button, .js-cart-quantity__btn--increment');
    }

    /**
     * @inheritdoc
     */
    getSlidesToRenderCount () {
        const sliderWidth         = this.container.offsetWidth || 0,
              slideWidth          = 124,
              betweenSlidesMargin = 20;
        let slidesWidth, slidesToRender = 0;
        do {
            slidesWidth = ++slidesToRender * (slideWidth + betweenSlidesMargin) - betweenSlidesMargin;
        } while (slidesWidth < sliderWidth);
        return slidesToRender + 1;
    }

    /**
     * Adds navigation buttons
     * @inheritdoc
     */
    afterSliderInit () {
        if (this.title) {
            const titleElement = document.createElement('div');
            titleElement.className = 'prk-product-slider__title';
            titleElement.innerHTML = `<span class="prk-product-slider__title-value">${this.title}</span>`;
            this.container.insertBefore(titleElement, this.container.firstChild);
        }
    }

    /**
     * Updates status of 'add to cart' buttons
     * @inheritdoc
     */
    afterSlidesRendered () {
        super.afterSlidesRendered();
        window['updateCustomerView'] && window['updateCustomerView']();
    }

    /**
     * Updates title
     * @private
     * @param {string} newTitle
     */
    updateTitle (newTitle) {
        this.title = newTitle;
        this.container.dataset.title = newTitle;
        const titleElement = this.container.querySelector('.prk-product-slider__title-value');
        if (titleElement)
            titleElement.innerHTML = newTitle;
    };

    /**
     * Creates product from a slide data object
     * @private
     * @param {{
     *  [key: string]: any,
     *  url: string,
     *  title: string,
     *  preview_picture_url: string,
     *  discount_price: number,
     *  base_price: number,
     *  product_id: number,
     *  is_our_brand: boolean,
     *  is_pickup_only: boolean,
     *  is_premium_product: boolean,
     * }} slideData
     */
    createProduct (slideData) {
        return new Product({
            url: slideData.url.replace(/(https?:\/\/)([^\/]*)(.*)/, `$1${this.params.catalogDomain}$3`),
            title: slideData.title,
            previewPictureUrl: slideData.preview_picture_url,
            discountPrice: slideData.discount_price,
            basePrice: slideData.base_price,
            productId: slideData.product_id,
            isOurBrand: slideData.is_our_brand,
            isPickupOnly: slideData.is_pickup_only,
            isPremiumProduct: slideData.is_premium_product,
        });
    }

    /**
     * @inheritdoc
     */
    async loadNextSlidesFromDataProvider (shouldLoadAllSlides) {
        // For non RR and non ajax updatable sliders
        if (!this.params.type)
            return;

        const params = { offset: this.slides.length };

        if (!shouldLoadAllSlides)
            params.limit = this.slidesToLoad;

        for (const paramName of this.supportedQueryParams) {
            if (this.params[paramName])
                params[paramName] = this.params[paramName];
        }

        const response = await this.dataProvider.fetch(params);

        // In case angular destroyed PrkSlider during promise pending state
        if (!this.container) return [];

        // If response is empty and count of loaded slides is empty (first query)
        if ((null === response || !response.length) && !this.slides.length && this.params.changeTypeOnEmptyApiResponse) {
            switch (this.params.type) {
                case PrkProductSliderDataProvider.TYPES.PERSONAL:
                    this._params.type = PrkProductSliderDataProvider.TYPES.POPULAR;
                    this.updateTitle('Рекомендуем');
                    return await this.loadNextSlidesFromDataProvider(shouldLoadAllSlides);

                case PrkProductSliderDataProvider.TYPES.POPULAR_PRODUCTS_IN_CATEGORIES:
                case PrkProductSliderDataProvider.TYPES.POPULAR_PRODUCTS_IN_INTERESTED_CATEGORIES:
                    this._params.type = PrkProductSliderDataProvider.TYPES.POPULAR;
                    return await this.loadNextSlidesFromDataProvider(shouldLoadAllSlides);

                case PrkProductSliderDataProvider.TYPES.RECOMMENDED_PRODUCTS_IN_CATEGORIES:
                    this._params.type = PrkProductSliderDataProvider.TYPES.POPULAR_PRODUCTS_IN_CATEGORIES;
                    return await this.loadNextSlidesFromDataProvider(shouldLoadAllSlides);

                case PrkProductSliderDataProvider.TYPES.ALTERNATIVES:
                case PrkProductSliderDataProvider.TYPES.SEARCH:
                    this._params.type = PrkProductSliderDataProvider.TYPES.PERSONAL;
                    this.updateTitle('Вам может понравиться');
                    return await this.loadNextSlidesFromDataProvider(shouldLoadAllSlides);
            }
        }

        if (response.length < this.options.slidesToLoad || shouldLoadAllSlides)
            this.isAllSlidesLoaded = true;

        return response.map(this.createProduct.bind(this));
    }

    /**
     * Reads serverside rendered slides and loads their data into slider.
     * @inheritdoc
     */
    pickupRenderedSlides () {
        return [...this.container.querySelectorAll('.prk-product-slider__slide')];
    }

    /**
     * Returns id of recommendation block
     * @private
     * @returns {string|undefined}
     */
    get recommendationBlockId () {
        const page = this.params.page || undefined;
        const type = this.params.type || undefined;
        if (!page || !type)
            return undefined;

        return `${page}_${type}`;
    }

    /**
     * Returns product id of a slide
     * @private
     * @param {HTMLElement} searchFromElement
     * @returns {number|null}
     */
    getProductId (searchFromElement) {
        const productElement = parentElements(searchFromElement, parent => parent.classList.contains('c-product'))[0];
        const productId = productElement instanceof HTMLElement
            ? parseInt(productElement.dataset.productId)
            : null;
        return isNaN(productId) ? null : productId;
    }

    /**
     * @private
     * @param {MouseEvent|TouchEvent|PointerEvent} event
     */
    async onLinkClick (event) {
        // Have to save currentTarget onto a variable
        // because event is unaccessible after await.
        // Can use event.currentTarget because eventListener
        // is directly assigned to links
        const link = event.currentTarget;
        if (!link.href || !this.dataProvider) return;

        // If there is onLickClick handler in dataProvider
        // wait for it to do its work, because immediately
        // following the link cancels http requests in modern browsers.

        // Cancel opening the link to correctly send tracking events
        event.preventDefault();

        // Wait until tracking events are sent
        const productId = this.getProductId(link),
              recomBlockId = this.recommendationBlockId;

        // If product id and recommendation block id are defined
        // send tracking event and wait for it to finish before
        // navigating to prevent http call cancellation.
        // But use 300ms timeout to navigate for long server responses.
        if (productId && recomBlockId) {
            let timeoutToNavigate = setTimeout(() => window.location.href = link.href, 300);

            // always resolves
            await this.dataProvider.fetch(
                {
                    type: this.params.type,
                    event: PrkProductSliderDataProvider.TRACKING_EVENTS.RECOM_TAP
                },
                { productId, recomBlockId }
            );
            clearTimeout(timeoutToNavigate);
        }

        // Now may follow the link
        window.location.href = link.href;
    }

    /**
     * @private
     * @param {MouseEvent|TouchEvent|PointerEvent} event
     */
    onLikeButtonClick (event) {
        const button = event.currentTarget;

        const productId = this.getProductId(button);
        const recomBlockId = this.recommendationBlockId;

        if (productId && recomBlockId) {
             this.dataProvider.fetch(
                {
                    type: this.params.type,
                    event: PrkProductSliderDataProvider.TRACKING_EVENTS.RECOM_TAP
                },
                { productId, recomBlockId }
            );
        }
    }

    /**
     * @private
     * @param {MouseEvent|TouchEvent|PointerEvent} event
     */
    async onBuyButtonClick (event) {
        if (!this.dataProvider) return;

        const productId = this.getProductId(event.target),
              recomBlockId = this.recommendationBlockId;

        if (productId && recomBlockId) {
            // always resolves
            await this.dataProvider.fetch(
                {
                    type: this.params.type,
                    event: PrkProductSliderDataProvider.TRACKING_EVENTS.RECOM_ADD_TO_BASKET
                },
                { productId, recomBlockId }
            );
        }
    }

    /**
     * EventListener that sends tracking event when
     * a user clicks on a navigation arrow
     * @private
     */
    async onProductSliderArrowClick () {}

    /**
     * EventListener that sends tracking event when
     * a user scrolls slider into viewport
     * @private
     */
    async onSliderGetIntoViewport () {
        const sliderContainerTop = this.container.getBoundingClientRect()['top'];
        if (sliderContainerTop < 600) {
            window.removeEventListener('scroll', this.eventListeners.onSliderGetIntoViewport);

            // Fixes PORYADOKRU-BD0 on ios.
            // Render next page of slides when slider is shown onscreen
            // instead of first interaction, otherwise click handlers
            // loose context (event.target) in ios.
            // Render next page of slides only when loop mode is on,
            // as new algorithm of scrolling on mobiles
            // doesn't require next page to be rendered for smooth scrolling
            if (this.isLoopModeEnabled)
                this.renderNextSlides();

            if (!this.dataProvider) return;
            const recomBlockId = this.recommendationBlockId;

            if (recomBlockId) {
                // always resolves
                await this.dataProvider.fetch(
                    {
                        type: this.params.type,
                        event: PrkProductSliderDataProvider.TRACKING_EVENTS.RECOM_BLOCK_VIEWED
                    },
                    { recomBlockId }
                );
            }
        }
    }

    /**
     * Initilizes logic specific to PrkProductSlider.
     * @private
     */
    initProductSliderSpecificLogic () {
        // Wрen slides failed to load exit
        if (!this.slides.length)
            return;

        window.addEventListener('scroll', this.eventListeners.onSliderGetIntoViewport, { passive: true });
        this.eventListeners.onSliderGetIntoViewport(); // For the case when the slider is already onscreen

        for (const arrow of this.arrows)
            arrow.addEventListener('click', this.eventListeners.onProductSliderArrowClick);
    }

    /**
     * Cleans up
     * @public
     */
    destroy () {
        window.removeEventListener('scroll', this.eventListeners.onSliderGetIntoViewport);

        for (const slide of this.slides)
            this.detachHandlersFromSlide(slide);

        for (const arrow of this.arrows)
            arrow.removeEventListener('click', this.eventListeners.onProductSliderArrowClick);

        super.destroy();
    }
}

// Make constructor available on window for angular
// as current version of angular & ts do not support
// static properties on classes and hence directly
// importing PrkProductSlider isn't possible
window.PrkProductSlider = PrkProductSlider;
export default PrkProductSlider
