__webpack_public_path__ = CHUNKS_PUBLIC_PATH;

// Don't make listeners passive on pages with yandex map (otherwise map will work incorrectly on scroll)
if (!window.location.pathname.startsWith('/personal/checkout') && !window.location.pathname.startsWith('/pickup/')) {
    require('default-passive-events');
}

import '/js/fetch'; // Patch native fetch
import 'lazysizes';
import { loadAutocomplete } from '../async/loadAutocomplete.js';
import {initPWA} from '../pwa/main';
import {
    copyTextToClipboard,
    initComparing,
    initFixedHeaderArea,
    initJSLinkHandler,
    initLoadMore,
    initViewToggles,
    initMaskedInput,
    initPaymentOnCheckoutConfirmPage,
    initPrkCollapsibles,
    initPrkProductSliders,
    initPrkTopMenu,
    initProductImageSliders,
    initSearchBox,
    isMobile,
    setMetrikaGoal,
    triggerAddToCartExternalEvents,
    triggerDownloadAppExternalEvents,
} from './functions';
import {hideBlankLoader, hideLoader, showBlankLoader, showLoader, waitForAndDo} from '../globalFunctions';
import { showMessage } from '../utils/showMessage';
import {initUserOnboarding} from "./onboarding";
import { parseHTML } from '../utils/parseHTML';
import { centerImageInsideOfContainer } from '../utils/centerImageInsideOfContainer';
import { logError } from '../utils/logError';
import { ready } from '../utils/ready';
import Viewport from './viewport';
import $ from '/js/dom7';
import URLQueryParams from '/js/url-query-params';
import {ProductImageSlider} from '../prk/product-image-slider';
import {handleLinkOpenClick, onPageLoad, prkModalConfigs} from './hashed-links-handlers';
import { initChips } from '../prk/ui/chips';
import { initSentry } from './sentry';
import { checkHost } from './check-host.js';

checkHost();

let Cookies = require('js-cookie');
let Slideout = require('slideout');

// First init Sentry
initSentry(ENVIRONMENT, RELEASE);

// Init Progressive Web App logic
initPWA();

ready(async function() {
    /**
     * Center images.
     * Good with lazysizes: @link https://afarkas.github.io/lazysizes/index.html
     * @var {HTMLImageElement} image
     */
    for (const image of [...document.querySelectorAll('img.center-image')]) {
        if (image.naturalWidth && image.naturalHeight)
            centerImageInsideOfContainer.call(image);
        else
            image.onload = centerImageInsideOfContainer;
    }

    initPrkTopMenu();
    initPrkProductSliders();
    initHandlingHashedLocationAndLinks();
    initPrkCollapsibles();
    initSearchBox();
    initChips();
    initComparing();
    initMaskedInput('.js-russian-phone-format');
    initJSLinkHandler();
    initLoadMore();
    initViewToggles();

    if (!isMobile()) {
        initProductImageSliders();
    }

    // Render personal information
    waitForAndDo(function () {
        return window['updateCustomerView'];
    }, function () {
        window.updateCustomerView();
    });

    // Init new users onboarding
    if ($('.js-new-user-timer').length) {
        initUserOnboarding();
    }

    // Reach goal of app download
    const appDownloadLinks = [...document.querySelectorAll('.js-app-link')];
    for (const link of appDownloadLinks) {
        link.addEventListener('click', triggerDownloadAppExternalEvents);
    }

    prkModalConfigs['geo_ip'] = {
        useHistory: false, // Prevent modal from modifying hash
        onOpen: async function() {
            if (this.modal.classList.contains('loaded'))
                return;

            try {
                const error = this.modal.querySelector('div.error');
                if (error) {
                    error.remove();
                }

                this.modal.classList.add('loaded');

                loadAutocomplete().then(() => {
                    jQuery('#select_other_city_input').easyAutocomplete({
                        url: '/api/prk/app/location/suggest/',
                        getValue: 'short_name',
                        template: {
                            type: 'custom',
                            method: (value, item) => {
                                return `${value}`;
                            }
                        },
                        listLocation: 'suggestions',
                        minCharNumber: 2,
                        ajaxSettings: {
                            dataType: 'json',
                            contentType: "application/json",
                            method: 'POST',
                            data: {}
                        },
                        preparePostData: data => {
                            data.type  = "city";
                            data.query = $('#select_other_city_input').val();
                            return JSON.stringify(data);
                        },
                        list: {
                            maxNumberOfElements: 8,
                            onClickEvent: () => {
                                const item = jQuery('#select_other_city_input').getSelectedItemData();

                                fetch('/api/prk/app/checkout/location/search/', {
                                    method: 'POST',
                                    headers: {
                                        'X-Requested-With': 'XMLHttpRequest',
                                    },
                                    body: JSON.stringify({
                                        data: {
                                            settlement: item.settlement,
                                            city: item.city,
                                            region: item.region,
                                            sub_region: item.sub_region,
                                            full_name: item.full_name,
                                        }
                                    })
                                })
                                  .then(response => response.json())
                                  .then(location => {
                                      $('#geo_ip .input-group button').attr('data-attr_city-code', location.location_info.bitrix_code);
                                  })
                                  .catch(logError);
                            },
                            match: {
                                enabled: false
                            }
                        },
                        requestDelay: 300,
                        theme: 'green-light',
                        adjustWidth: false
                    });
                }).catch(logError);
            } catch (error) {
                logError(error);
                // Use append not to remove close button.
                this.modal.append(...parseHTML('<div class="error">Ошибка, попробуйте позже</div>'));
            } finally {
                hideLoader($(this.modal));
            }
        }
    };
    prkModalConfigs['pay_delivery_modal'] = {
        onOpen: async function() {
            if (this.modal.classList.contains('loaded'))
                return;

            try {
                showLoader($(this.modal));
                const error = this.modal.querySelector('div.error');
                if (error) error.remove();

                const response = await fetch('/include/pay_delivery_modal.php', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                });
                if (!response.ok)
                    throw new Error('Error loading #pay_delivery_modal content');

                // Use append not to remove close button.
                this.modal.append(...parseHTML(await response.text()));
                this.modal.classList.add('loaded');
                equalize('.equalize table.responsive tr', 600, '#pay_delivery_modal');
            } catch (error) {
                logError(error);
                this.modal.append(...parseHTML('<div class="error">Ошибка, попробуйте позже</div>'));
            } finally {
                hideLoader($(this.modal));
            }
        }
    }

    //Copies image and moves the copy to another position
    function flyImage(imgToDrag, offset) {
        const viewport = new Viewport(),
              { left, top, width, height } = viewport.getElementPosition(imgToDrag[0]);

        imgToDrag.clone()
            .css({
                'opacity': '0.5',
                'position': 'absolute',
                'height': `${height}px`,
                'width': `${width}px`,
                'z-index': '100',
                'top': `${top}px`,
                'left': `${left}px`,
            })
            .appendTo(document.body)
            .animate({
                'top': offset.top,
                'left': offset.left,
                'width': 0,
                'height': 0
            }, {
                duration: 1000,
                complete: () => $(this).remove(),
            });
    }

    // Takes DOM element and shakes it
    /**
     * @param {HTMLElement} element
     * @param {number} delay
     */
    function shakeElement(element, delay) {
        // Initialize default parameters
        delay = delay || 0;
        const onShake  = () => {
            element.removeEventListener('animationend', onShake);
            element.classList.remove('shake-animation')
        };

        setTimeout(function () {
            element.addEventListener('animationend', onShake);
            element.classList.add('shake-animation');
        }, delay);
    }

    // Add product to cart
    $(document).on('click', '.add-to-cart, .quick-view, .js-cart-quantity__btn--increment, .js-cart-quantity__btn--decrement', function () {
        const $this = $(this);
        const productBox = $this.parents('.product');
        const prodId = $this.attr('data-attr_prod-id');
        const basketBalance = Number($this.attr('data-attr_bskt-quantity')) || 0;
        const quantity = $this.hasClass('js-cart-quantity__btn--decrement')
            ? basketBalance - 1
            : basketBalance + 1;
        const viewport = new Viewport();

        showLoader(productBox, 'productBoxLoader');
        showBlankLoader($('.bottom-info-line'), 'bottomLineLoader');

        fetch('/api/prk/catalog/product/add-to-basket/', {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: new URLQueryParams({ 'prod_id': prodId, 'q': quantity }),
        })
            .then(response => response.json())
            .then(basketData => {
                hideLoader(productBox, 'productBoxLoader');
                hideBlankLoader($('.bottom-info-line'), 'bottomLineLoader');

                if (basketData.type !== 'success') {
                    showMessage('Не удалось добавить товар в корзину. Пожалуйста, попробуйте позже');
                    return;
                }

                // Update basket data if we are add to basket from angular checkout component
                if (window.location.pathname.startsWith('/personal/checkout')) {
                    if (typeof window['angular']['checkout']['reloadCart'] === 'function') {
                        window['angular']['checkout']['reloadCart']();
                    } else {
                        window.location.reload();
                    }
                } else {
                    // Update customer view with fresh data
                    window.customerData.basket = basketData.data;
                    updateCustomerBasketView(window.customerData.basket);
                    const newProductQuantity = basketData.data.PRODUCT_ID_2_QUANTITY_MAP[prodId] ?? 0;
                    const isProductDeleted = 0 === newProductQuantity;
                    if (isProductDeleted) {
                        updateDeletedCartItem($this);
                    }

                    if ($this.hasClass('js-fly-btn')) {
                        const imgToDrag = productBox.find('.img img');
                        const cart = document.querySelector('.js-cart-product-image-fly-target');

                        if (imgToDrag.length && cart) {
                            const { left, top, width, height } = viewport.getElementPosition(cart);
                            const offset = {
                                top: top + height / 2,
                                left: left + width / 2
                            };

                            flyImage(imgToDrag, offset);
                            shakeElement(cart, 1000);
                        }
                    }

                    // Do no trigger events on deleting product from cart
                    if (basketData.product) {
                        // Trigger external add to cart events
                        triggerAddToCartExternalEvents(
                            prodId,
                            basketData.product.NAME || '',
                            Number(basketData.product.PRICE || 0.0),
                            quantity || 1,
                            productBox.hasClass('prk-product-slider__product') && !productBox.parents('.sale-product-list').length
                        );
                    }
                }
            })
            .catch(reason => {
                logError(reason);
                hideLoader(productBox, 'productBoxLoader');
                hideBlankLoader($('.bottom-info-line'), 'bottomLineLoader');
            });
    });

    // RetailRocket product recommendations block
    if ($('.quick-view').length) {
        const recommendationsBlock = $('.js-retailrocket-product-recommendations-block');
        if (recommendationsBlock.length) {
            let idOfProductHavingRecommendationsBlock;
            $(document).on('click', '.quick-view', function(e) {

                if (window.location.pathname.startsWith('/personal/checkout'))
                    return false;

                const productId = $(this).attr('data-attr_prod-id');

                if (productId !== idOfProductHavingRecommendationsBlock) {

                    if (recommendationsBlock.hasClass('active')) {
                        recommendationsBlock.fadeTo(400, 0, () => {
                            recommendationsBlock.removeClass('active');
                        });
                    }

                    fetch('/include/retailrocket-product-recommendations.php', {
                        method: 'POST',
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                        },
                        body: new URLQueryParams({ 'product_id': productId })
                    })
                        .then(response => response.text())
                        .then(htmlTemplate => {
                            const rrRecommendationsWrapper = document.querySelector('.js-retailrocket-recommendations-wrapper');
                            if (rrRecommendationsWrapper) {
                                rrRecommendationsWrapper.parentNode?.removeChild(rrRecommendationsWrapper);
                            }

                            if (htmlTemplate) {
                                recommendationsBlock.html(htmlTemplate);

                                // Init PrkProductSlider
                                if (document.querySelector('.js-retailrocket-recommendations-wrapper .prk-product-slider'))
                                    initPrkProductSliders();

                                /* Used setInterval to show recommendationsBlock as fast as possible
                                    checking (every 200 ms) are there rendered RetailRocket items in html */
                                let triesCount = 0;
                                const interval = setInterval(() => {
                                    if (triesCount > 10) {
                                        clearInterval(interval);
                                    } else {
                                        const recommendations = document.querySelectorAll('.js-retailrocket-recommendations-wrapper div[data-rendered-items-ids], .js-retailrocket-recommendations-wrapper .prk-product-slider__slide');
                                        if (recommendations.length) { // Check if recommendations are rendered
                                            clearInterval(interval);

                                            // Check if recommendations more than one
                                            if (recommendations.length > 1) {
                                                idOfProductHavingRecommendationsBlock = productId;

                                                setTimeout(() => {
                                                    recommendationsBlock.addClass('active').fadeTo(400, 1);
                                                }, 500);
                                            }
                                        }
                                    }

                                    triesCount++;
                                }, 200);
                            }
                        })
                        .catch(logError);
                }
            });

            $(document).on('click', '.js-retailrocket-recommendations-wrapper .js-close-btn', () => {
                recommendationsBlock.fadeTo(400, 0, () => {
                    recommendationsBlock.css("display", "");
                    recommendationsBlock.removeClass('active');
                });
            });
        }
    }

    // Hide Retailrocket loader
    if ($('.js-loader.rr-preview-loader').length) {
        setTimeout(async () => {
            $('.js-loader.rr-preview-loader').fadeOut();
        }, 5000);
    }

    // Add product to card without effects
    $(document).on('click', '.js-addToCart', /** @this {HTMLElement} */ function() {
        const productId = this.dataset.attr_prodId;
        const basketBalance = Number(this.dataset.attr_bsktQuantity) || 0;

        // Do not change the quantity in basket,
        // can only add to basket if the product not already present in it
        const quantity = basketBalance || 1;

        fetch('/api/prk/catalog/product/add-to-basket/', {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: new URLQueryParams({ 'prod_id': productId, 'q': quantity }),
        })
            .then(response => response.json())
            .then(basketData => {
                if (basketData.type !== 'success') {
                    showMessage('Не удалось добавить товар в корзину. Пожалуйста, попробуйте позже');
                    return;
                }

                var productBox = $(this).parents('.product');
                showLoader(productBox);
                showBlankLoader($('.bottom-info-line'));

                // Fire analytics events
                setMetrikaGoal('click_oneclick');

                if (basketData.product) {
                    // Trigger external add to cart events
                    triggerAddToCartExternalEvents(
                        productId,
                        basketData.product.NAME || '',
                        Number(basketData.product.PRICE || 0.0),
                        quantity || 1,
                        productBox.hasClass('prk-product-slider__product') && !productBox.parents('.sale-product-list').length
                    );
                }

                window.location.href = '/personal/checkout/';
            })
            .catch(logError);
    });

    // Add to favorite list
    $(document).on('click', '.js-add-to-fav', function () {
        const $this = $(this),
              productBox = $this.parents('.catalog-product'),
              prodId = $this.attr('data-attr_prod-id');

        showLoader(productBox);
        showBlankLoader($('.bottom-info-line'));

        // Fire analytics events
        setMetrikaGoal('click_wishlist');

        fetch('/api/prk/personal/favorites/', {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: new URLQueryParams({ prod_id: prodId }),
        })
            .then(response => response.json())
            .then(favorites => {
                hideLoader(productBox);
                hideBlankLoader($('.bottom-info-line'));

                // Update customer view with fresh data
                window.customerData.favorites = favorites;
                updateCustomerFavoriteView(window.customerData.favorites);
            })
            .catch(reason => {
                logError(reason);
                hideLoader(productBox);
                hideBlankLoader($('.bottom-info-line'));
            })
    });

    // Load stores dropdown
    if ($('.header .c-select .dropdown-toggle')) {
        $(document).on('click', '.header .c-select .dropdown-toggle', function () {
            let $button = $(this);
            let $dropdownMenu = $button.next();
            let $dropdown = $button.parent();
            if (!$(this).hasClass('loaded')) {
                showLoader($dropdownMenu);
                fetch('/include/store_list.php', {
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                })
                    .then(response => response.text())
                    .then(htmlTemplate => {
                        hideLoader($dropdownMenu);
                        $dropdownMenu.html(htmlTemplate);
                        $button.addClass('loaded');
                    })
                    .catch(reason => {
                        logError(reason);
                        hideLoader($dropdownMenu);
                    });
            }

            if ($dropdownMenu.hasClass('show')) {
                $dropdownMenu.removeClass('show');
                $dropdown.attr('aria-expanded', false);
            } else {
                $dropdownMenu.addClass('show');
                $dropdown.attr('aria-expanded', true);
            }
        });
    }

    // Close button for popups: removes parent div
    $(document).on('click', '.js-close', function () {
        $(this).parent().remove();
    });

    // Set user city
    $(document).on('click', '#geo_ip span.submit, .js-list-of-cities .location-selection__list-item, button[data-attr_city-code]:not([data-attr_city-code=""])', function(e) {
        let code = this.getAttribute('data-attr_city-code');

        if (!code) {
            const option = document.querySelector('.js-list-of-cities > *[data-attr_city-code]:not([data-attr_city-code=""])');
            if (option) {
                code = option.getAttribute('data-attr_city-code');
            }
        }

        if (this.classList.contains('location-selection__list-item')) {
            e.preventDefault();
        }

        showLoader($('#geo_ip .modal-body'));
        fetch('/include/save_city.php', {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: new URLQueryParams({ code }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.text == 'ok') {
                    Cookies.set('CITY_CODE', code, { expires: 365 * 10, domain: '.poryadok.ru' });
                    try {
                        if (data.redirectTo) {
                            // When server returned redirectTo
                            location.href = data.redirectTo;
                        } else if (this.href) {
                            // When href is available on city link
                            const redirectUrl = location.href.replace(location.origin + '/', this.href);
                            location.href = redirectUrl;
                        } else {
                            throw new Error('Redirect failed');
                        }
                    } catch (error) {
                        // When something goes wrong or failed to identify redirect url 
                        location.reload();
                    }
                } else {
                    showMessage('Что-то пошло не так. Пожалуйста, попробуйте позже');
                }
            })
            .catch(reason => {
                hideLoader($('#geo_ip .modal-body'));
                logError(reason);
                showMessage('Что-то пошло не так. Пожалуйста, попробуйте позже');
            });
    });

    // Dynamic bottom info line
    let scrollLimit = $(window).height() / 3;
    let $bottomInfoLine = $('.bottom-info-line');
    let isBottomInfoLineVisible = false;
    if ($(window).width() > 750) {
        const viewport = new Viewport();
        window.addEventListener('scroll', () => {
            if (!isBottomInfoLineVisible && viewport.scrollTop > scrollLimit) {
                $bottomInfoLine.fadeIn();
                isBottomInfoLineVisible = true;
            }
            else if (isBottomInfoLineVisible && viewport.scrollTop <= scrollLimit) {
                $bottomInfoLine.fadeOut();
                isBottomInfoLineVisible = false;
            }
        }, { passive: true });
    }

    if ($('.info-pages').length) {
        $(document).on('click', '.info-pages.job .vacancy-item .title', function () {
            $(this).toggleClass('active').siblings('.vacancy-detail').toggle();
        });
    }

    // *** CITY SELECT EVENTS ***
    // Show GeoIP popup with delay
    if ($('.geoip-popup').length) {
        setTimeout(() => {
            $('.geoip-popup').fadeIn(1000);
        }, 300);
    }

    $(document).on('click', '.js-inaccessible', function() {
        return false;
    });

    initPaymentOnCheckoutConfirmPage();

    $(document).on('click', '.product-code .highlight, b.highlight', function() {
        copyTextToClipboard(this);
        $(this).attr('aria-label', 'Код скопирован!');
    });

    // Track user's click on product variation
    [...document.querySelectorAll('.js-product-variation')]
        .forEach((el) => el.addEventListener('click', () => setMetrikaGoal('view_product_variation')));
});

ready(function() {
    $('.tabs-buttons').on('click', '.tabs-buttons__item:not(.tabs-buttons__item--active)', function() {
        $(this)
            .addClass('tabs-buttons__item--active')
                .siblings()
                .removeClass('tabs-buttons__item--active')
            .closest('.tabs')
                .find('.tabs__content')
                .removeClass('tabs__content--active')
                .eq($(this).index())
                .addClass('tabs__content--active');
    });

});

function equalize(elem, minWidth, container) {
    let $elem = $(elem);
    if($(container).width()>minWidth){
        var count = $elem.length / $elem.parents("table.responsive").length;
        for(var i = 0;i<count;i++) {
            var $line = $($elem.selector+":nth-child("+i+")");
            $line.css("height","auto");
            var height = Math.max.apply(null, $line.map(function() {
                return $(this).height();
            }).get());
            $line.css("height", height+1);
        }
        return true;
    } else {
        $elem.css("height","auto");
        return false;
    }
}

// Mobile Drawer (Slideout)
const $drawer = $('#drawer');
if ($drawer.length) {
    (function () {
        function closeSlideout(event) {
            event.preventDefault();
            slideout.close();
        }

        const slideout = new Slideout({
            'panel': document.getElementById('wrapper'),
            'menu': document.getElementById('drawer'),
            // Don't allow touch events on checkout
            'touch': !window.location.pathname.startsWith('/personal/checkout')
        });

        slideout
            .on('beforeopen', function () {
                this.panel.classList.add('drawer-open');

                [...document.querySelectorAll('[data-hide-on-slideout]')]
                    .forEach((item) => item.hidden = true);

                if (this.menu.dataset.isInitialized !== 'true') {
                    this.menu.dataset.isInitialized = 'true';
                    showLoader($drawer);
                    fetch(`/include/widgets/drawer/?callbackUri=${location.pathname + location.search}`, {
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                        },
                    })
                        .then(response => response.text())
                        .then(htmlTemplate => {
                            this.menu.innerHTML = htmlTemplate;

                            /** By default, scripts inserted through innerHTMl does not execute */
                            /** Replaces scripts with new ones as drawer contains externals scripts to execute */
                            Array.from(this.menu.querySelectorAll('script'))
                                .forEach(oldScriptEl => {
                                    const newScriptEl = document.createElement("script");
                                    Array.from(oldScriptEl.attributes).forEach(attr => {
                                        newScriptEl.setAttribute(attr.name, attr.value)
                                    });
                                    const scriptText = document.createTextNode(oldScriptEl.innerHTML);
                                    newScriptEl.appendChild(scriptText);
                                    oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
                                });

                            hideLoader($drawer);

                            // Init stores collapsible
                            const handle = document.querySelector('.js-load-mobile-prk-store-list'),
                                eventName = 'before-expand';

                            const loadPrkStoreList = function (/** @this {HTMLElement} .mobile-menu-collapsible */) {
                                // Remove event listener so that event doesn't fire twice
                                this.removeEventListener(eventName, loadPrkStoreList);

                                const $cListMobile = $(this.querySelector('.c-list-mobile'));
                                let loaderTimeout = setTimeout(showLoader, 200, $cListMobile);
                                fetch('/include/store_list.php', {
                                    method: 'POST',
                                    headers: {
                                        'X-Requested-With': 'XMLHttpRequest',
                                    },
                                })
                                    .then(response => response.text())
                                    .then(htmlTemplate => {
                                        clearTimeout(loaderTimeout);
                                        loaderTimeout = null;
                                        hideLoader($cListMobile);
                                        $cListMobile.html(htmlTemplate);
                                    })
                                    .catch(reason => {
                                        logError(reason);
                                        hideLoader($cListMobile);
                                    });
                            };

                            if (!handle) return;
                            handle.addEventListener(eventName, loadPrkStoreList);
                        })
                        .catch(reason => {
                            logError(reason);
                            hideLoader($drawer);
                        });
                }
            })
            .on('open', function () {
                this.panel.addEventListener('click', closeSlideout);
            })
            .on('beforeclose', function () {
                this.panel.classList.remove('drawer-open');
                this.panel.removeEventListener('click', closeSlideout);

                [...document.querySelectorAll('[data-hide-on-slideout]')]
                    .forEach((item) => item.hidden = false);
            });

        $('.js-toggle-drawer').on('click', function (event) {
            event.stopPropagation();
            slideout.toggle();
        });
    })();
}

if (!window.isMobileBackend) {
    ready(initFixedHeaderArea);
}

if (window.isMobileBackend) {
    ready(function() {
        const fixedAddToCartButtonBlock = document.querySelector('.js-fixed-add-to-cart-button');
        if (fixedAddToCartButtonBlock === null) return;

        // need up for correct work `position: fixed`
        // reason: css property `will-change`
        document.body.appendChild(fixedAddToCartButtonBlock);
    });
}

if (isMobile()) {
    new ProductImageSlider();
}

function initHandlingHashedLocationAndLinks () {
    document.addEventListener('click', handleLinkOpenClick);

    // Timeout allows other bundles to init PrkModals with custom parameters
    // before opening a modal from hash
    setTimeout(onPageLoad);
}