export default class Clock {
  /** @var {HTMLElement} */
  _element;

  /** @var {number} */
  _tickTimer;

  /** @var {{[key: string]: EventListener}} */
  _eventListeners;

  /** @var {Date} */
  _time;

  /**
   * @param {HTMLElement} element
   * @param {Date} time
   */
  constructor(element, time) {
    this._element = element;
    this._time = time;
    this._eventListeners = {
      tick: this.tick.bind(this),
    };

    this._element.classList.add('clock');

    this.render();
    this._tickTimer = setInterval(this._eventListeners.tick, 1000);
  }

  /**
   * Ticks every second and updates the view
   * @return {void}
   */
  tick() {
    this._time = new Date(this._time.getTime() + 1000);
    this.render();
  }

  /**
   * Renders time
   * @return {void}
   */
  render() {
    const { hours, minutes, seconds } = this.getTime();
    this._element.innerText = `${this.getTwoDigitTimePart(hours)} : ${this.getTwoDigitTimePart(minutes)} : ${this.getTwoDigitTimePart(seconds)}`;
  }

  /**
   * Returns hours, minutes or seconds in 2 digit format
   * @param {number} hoursOrMinutesOrSeconds
   * @return {string}
   */
  getTwoDigitTimePart(hoursOrMinutesOrSeconds) {
    return `0${hoursOrMinutesOrSeconds}`.slice(-2);
  }

  /**
   * @return {{ hours: number, minutes: number, seconds: number }}
   */
  getTime() {
    const hours = this._time.getHours(),
          minutes = this._time.getMinutes(),
          seconds = this._time.getSeconds();

    return { hours, minutes, seconds };
  }
}
