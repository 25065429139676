import { handleModuleLoadingError } from './handleModuleLoadingError.js';

/**
 * @param {boolean} customErrorHandling pass TRUE to use custom error handling
 * @return {Promise<IMask>}
 */
export const loadIMaskPhone = (customErrorHandling) => {
    const promise = import('imask/esm/imask').then(m => m.default) // IMask core
            .then(IMask => {
                return new Promise((resolve, reject) => {
                    import('imask/esm/masked/pattern') // IMask/Pattern
                        .then(() => resolve(IMask))
                        .catch(reject);
                });
            });

    if (!customErrorHandling)
        promise.catch(handleModuleLoadingError);

    return promise;
};
