import { showMessage } from './utils/showMessage';

window['waitForAndDo'] = waitForAndDo;
export function waitForAndDo(condition, callback) {
    if (typeof condition() !== "undefined") {
        callback();
    } else {
        setTimeout(function () {
            waitForAndDo(condition, callback);
        }, 0);
    }
}

// Show/Hide Standard Loader
const DEFAULT_LOADER_ID = 'ajaxLoader';
window['showLoader'] = showLoader;
export function showLoader(box, loaderId = DEFAULT_LOADER_ID, opacity = 0.5, isBlank = false) {
    if (!box)
        box = $('body');

    if (box.length && box.length > 1)
        box = $(box[0]);

    if (isBlank)
        loaderId += 'Blank';

    let loaderClass = isBlank ? 'ajax-loader-blank' : 'ajax-loader';
    let loaderHTML = '<div id="' + loaderId + '" class="' + loaderClass + '"></div>';
    box.append(loaderHTML);
    box.animate({opacity: opacity}, 'fast');

    let loader = $('#' + loaderId);
    loader.height(box.outerHeight());
    loader.show();
}

window['hideLoader'] = hideLoader;
export function hideLoader(box, loaderId = DEFAULT_LOADER_ID, isBlank = false) {
    if (!box)
        box = $('body');

    if (box.length && box.length > 1)
        box = $(box[0]);
        
    if (isBlank)
        loaderId += 'Blank';

    let loaderSelector = '#' + loaderId;
    $(loaderSelector).hide();
    box.find(loaderSelector).remove();
    box.animate({opacity: 1.0}, 'fast');
}

// Show/Hide Blank Loader
window['showBlankLoader'] = showBlankLoader;
export function showBlankLoader(box, loaderId, opacity = 0.7) {
    showLoader(box, loaderId, opacity, true);
}
window['hideBlankLoader'] = hideBlankLoader;
export function hideBlankLoader(box, loaderId) {
    hideLoader(box, loaderId, true);
}

window.showMessage = showMessage;
