/** 
 * @param {HTMLElement} element 
 * @param {?(a: HTMLElement) => boolean} predicate
 * @returns {HTMLElement[]}
 */
export const parentElements = (element, predicate) => {
    const parents = [];

    while (element = element.parentElement) {
        if (predicate && !predicate(element)) {
            continue;
        }

        parents.push(element);
    }
    
    return parents;
}
