/**
 * Returns cancelAnimationFrame function supported by browser
 * @returns {Function}
 */
export const cAF = (() => {
  return  window.cancelAnimationFrame ||
          window.webkitCancelRequestAnimationFrame ||
          window.mozCancelAnimationFrame ||
          function (handle) {
            return clearTimeout(handle)
          };
})();

export default cAF;