import { loadPrkModal } from '../async/loadPrkModal.js';
import { showLoader, hideLoader } from "../globalFunctions";
import { logError } from '../utils/logError';
import { scrollToElement } from './functions';

const TARGET_DATASET = 'prkModalTarget';

export const prkModalConfigs = {};

/**
 * Opens a .prk-modal or scrolls to an element
 * that is mentioned in hash on page load
 * @returns {void}
 */
export const onPageLoad = async function() {
    const hash = window.location.hash;
    if (!hash || hash === '#')
        return;

    const targetId = hash.replace(/^#/, '');
    let target;

    try {
        target = document.getElementById(targetId);
    } catch (error) {
        // Do not log invalid selector errors:
        // # can container utm_source and other params
    }

    if (!target)
        return;

    // Handle modals and scroll separately
    if (target.classList.contains('prk-modal')) {
        openModal(target);
    } else {
        scrollToElement(target);
    }
}

/**
 * A generic function to be attached on document
 * that opens a modal on [data-prk-modal-target] or scrolls on [href] elements
 * if the corresponding attribute contains an id of such an element
 * @param {MouseEvent|TouchEvent|PointerEvent} event
 * @returns {void}
 */
export const handleLinkOpenClick = async function(event) {
    /** @var {HTMLElement|null} button */
    let button = event.target;
    let targetId, target;

    while (button) {
        // When button should open a modal
        if (TARGET_DATASET in button.dataset) {
            targetId = button.dataset[TARGET_DATASET] || '';
            break;
        }

        // When button is a link
        if (button.hasAttribute('href')) {
            const href = (button.getAttribute('href') || '');

            // Check if button is a link to en element
            if (href[0] === '#') {
                targetId = href.replace(/^#/, '');
            }
            break;
        }

        if (!button.parentElement)
            break;

        // Try the parent element
        // for the case when click was made
        // on an internal element (like span or svg) of button
        button = button.parentElement;
    }

    if (!targetId) return;

    try {
        target = document.getElementById(targetId);
    } catch (error) {
        // Do not log invalid selector errors:
        // # can container utm_source and other params
    }

    if (!target) return;

    // Handle modals and scroll separately
    if (target.classList.contains('prk-modal')) {
        // Prevent following links when opening a modal
        event.preventDefault();
        openModal(target, true, event);
    } else {
       scrollToElement(target);
    }
}

/**
 * @param {HTMLElement} element
 * @param {boolean|undefined} showLoaderOnSlowConnection
 * @param {MouseEvent|TouchEvent|PointerEvent|undefined} event
 * @returns {void}
 */
const openModal = async function(element, showLoaderOnSlowConnection, event = undefined) {
    // Show loader only on slow connection
    let loaderTimeout;
    try {
        if (showLoaderOnSlowConnection) {
            loaderTimeout = setTimeout(showLoader, 200);
        }

        const PrkModal = await loadPrkModal();

        if (showLoaderOnSlowConnection) {
            clearTimeout(loaderTimeout);
            loaderTimeout = null;
            hideLoader();
        }

        (new PrkModal(element, prkModalConfigs[element.id])).open(event);
    } catch (error) {
        // But log errors like "failed to load chunk" and others
        logError(error);
    }
}
