/**
 * Decorator that modifies original function to be called with a delay,
 * also subsequent calls to that debounded function prolong the delay.
 * @param {Function} func original function to make debounded
 * @param {number} wait milliseconds to wait until function call
 * @param {boolean} immediate true to call function immediate
 * @returns {Function}
 */
export function debounce (func, wait, immediate) {
    let context, args, timeout, result, previous;

    const later = function () {
        const passed = Date.now() - previous;
        if (wait > passed) {
            timeout = setTimeout(later, wait - passed);
        } else {
            timeout = null;
            if (!immediate) result = func.apply(context, args);
            // This check is needed because `func` can recursively invoke `debounced`.
            if (!timeout) args = context = null;
        }
    }

    const debounced = function () {
        context = this;
        args = arguments;
        previous = Date.now();
        if (!timeout) {
            timeout = setTimeout(later, wait);
            if (immediate) result = func.apply(context, args);
        }
        return result;
    }

    debounced.cancel = function () {
        clearTimeout(timeout);
        timeout = args = context = null;
    }

    return debounced;
}

/**
 * Decorator that modifies original function to be called once in throttleTime,
 * but guaranties that if the function was called too soon, it will be executed after throttleTime.
 * @param {Function} fn original function to make throttled
 * @param {number} throttleTime in milliseconds
 * @returns {Function}
 */
export function throttle (fn, throttleTime) {
    let /** @var {boolean} */ isFunctionCallPlanned = false,
        /** @var {number|null} */ lastCallTime = null,
        /** @var {Array<any>|null} */ args = null,
        /** @var {any} */ context = null,
        /** @var {any} */ result;

    const normalizedThrottleTime = throttleTime > 0
        ? Math.floor(throttleTime)
        : null;

    const realFunctionCall = function () {
        result = fn.apply(context, args);
        lastCallTime = Date.now();
    };

    return function () {
        args = Array.prototype.slice.call(arguments);
        context = this;
        if (normalizedThrottleTime !== null && lastCallTime !== null) {
            if (!isFunctionCallPlanned) {
                const timeToNextFunctionCall = normalizedThrottleTime - (Date.now() - lastCallTime);
                if (timeToNextFunctionCall > 0) {
                    isFunctionCallPlanned = true;
                    setTimeout(function () {
                        realFunctionCall()
                        isFunctionCallPlanned = false
                    }, timeToNextFunctionCall);
                } else {
                    realFunctionCall();
                }
            }
        } else {
            realFunctionCall();
        }
        return result;
    };
}
