import { trigger } from 'dom7';

/**
 * Attaches onlaod event, or triggers it
 * @this {Dom7}
 * @returns {Dom7}
 */
export const load = function (callback) {
    if (callback instanceof Function) {
        this.on('load', callback);
    } else {
        trigger.call(this, 'load');
    }
};

export default load;
