import {isPWASupported} from './utils';
import {refreshPushSubscription} from "./subscriptions";

export function initPWA() {
    // Do nothing if PWA not supported by browser
    if (!isPWASupported())
        return;

    // Wait till page loaded for SW registration (https://developers.google.com/web/tools/workbox/guides/service-worker-checklist)
    window.addEventListener('load', function() {
        // Register PRK Service Worker
        navigator.serviceWorker.register('/prk-sw.js').then(function(registration) {
            refreshPushSubscription();
        }, /*catch*/ function(error) {
            // Service worker registration failed
            // TODO: investigate OS/devices which can not load current SW (eg Windows XP)
        });
    });
}
