import { handleModuleLoadingError } from './handleModuleLoadingError.js';
import { loadJQuery } from './loadJQuery.js';

/**
 * @param {boolean} customErrorHandling pass TRUE to use custom error handling
 * @return {Promise<unknown>}
 */
export const loadOwlCarousel = (customErrorHandling) => {
    // Can't load in parallel
    const promise = loadJQuery(true)
            .then(() => import('owl.carousel'));

    if (!customErrorHandling)
        promise.catch(handleModuleLoadingError);

    return promise;
};
