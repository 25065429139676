/**
 * Returns requestAnimationFrame function supported by browser
 * @returns {Function}
 */
export const rAF = (() => {
  return  window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.msRequestAnimationFrame ||
          window.oRequestAnimationFrame ||
          function (callback) {
            return window.setTimeout(function() { callback( Date.now() ) }, 16)
          };
})();

export default rAF;