import { showMessage } from './../utils/showMessage';
import { logError } from './../utils/logError';

export const handleModuleLoadingError = function (e) {
    if (!(e instanceof Error)) {
        e = new Error(e?.toString() || 'Undefined error when loading a module');
    }

    logError(e);
    showMessage('Произошла ошибка: пожалуйста, перезагрузите страницу');
};
