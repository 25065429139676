import { handleModuleLoadingError } from './handleModuleLoadingError.js';

/**
 * @param {boolean} customErrorHandling pass TRUE to use custom error handling
 * @return {Promise<JQuery>}
 */
export const loadJQuery = (customErrorHandling) => {
    const promise = import('jquery').then(m => {
        const jQuery = m.default;
        window.jQuery = jQuery;
        return Promise.resolve(jQuery);
    });

    if (!customErrorHandling)
        promise.catch(handleModuleLoadingError);

    return promise;
};
