import { ErrorWithContext } from './../../error-with-context';
import { logError } from './../logError';

/**
 * Loads a resource by url.
 * If tagName is provided inserts in into head.
 * If elementToInsertInto is alse provided, then inserts into that element
 * instead of head
 * 
 * @param {string} url
 * @param {string} tagName
 * @param {HTMLElement} elementToInsertInto
 * @return {PromiseLike<any, null>}
 */
export function loadResourceAsync(url, tagName, elementToInsertInto) {
    return fetch(url, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    })
        .then(response => {
            if (response.ok) return response.text();
            throw new Error(`${response.status}: (${url})`);
        })
        .then(function(resourceData) {
            if ('string' === typeof tagName && tagName.length) {
                const targetElement = elementToInsertInto instanceof HTMLElement
                    ? elementToInsertInto
                    : document.querySelector('head');

                if (targetElement) {
                    const element = document.createElement(tagName);
                    element.innerText = resourceData;
                    targetElement.appendChild(element);
                }
            }
            return resourceData;
        })
        .catch(function(error) {
            logError(error); // Log error to sentry
            return null;
        });
}

/**
 * Alias for loadResourceAsync for styles
 * @see loadResourceAsync
 * 
 * @param {string} url
 * @return {PromiseLike<any, null>}
 */
export function loadStyleAsync(url) {
    return loadResourceAsync(url, 'style');
}

/**
 * Alias for loadResourceAsync for scripts
 * @see loadResourceAsync
 * 
 * @param {string} url
 * @return {PromiseLike<any, null>}
 */
export function loadScriptAsync(url) {
    // Check if script is already loaded
    if (document.querySelector(`script[src="${url}"]`) instanceof HTMLElement)
        return Promise.resolve();

    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('async', 'async');
        script.onload = () => resolve()
        script.onerror = () => {
            script.parentElement.removeChild(script);
            reject(new ErrorWithContext('Failed loading async script', {
                extra: { url }
            }));
        }
        script.src = url;
        document.head.appendChild(script);
    });
}
