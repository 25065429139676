export class ErrorWithContext extends Error {
    /**
     * @var {{
     *  tags?: { [key:string]: any }
     *  extra?: { [key:string]: any }
     *  contexts?: { [key:string]: any }
     *  user?: { [key:string]: any }
     *  level?: { [key:string]: any }
     *  fingerprint?: { [key:string]: any }
     * }|undefined} context
     */
    context;

    /**
     * Error with context information for Sentry
     * @param {string} message 
     * @param {{
     *  tags?: { [key:string]: any }
     *  extra?: { [key:string]: any }
     *  contexts?: { [key:string]: any }
     *  user?: { [key:string]: any }
     *  level?: { [key:string]: any }
     *  fingerprint?: { [key:string]: any }
     * }|undefined} context 
     */
    constructor(message, context = undefined) {
        super(message);
        this.context = context;
    }
}