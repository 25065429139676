// From: https://stackoverflow.com/a/901144
export function getQueryParam(paramName) {
    const url = window.location.href;
    paramName = paramName.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + paramName + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
