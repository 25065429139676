import { logError } from './../utils/logError';
import URLQueryParams from '/js/url-query-params';

export class PrkProductSliderDataProvider {
    static API_URL = '/api/prk/product-slider/';

    static TYPES = {
        ACCESSORIES: 'accessories',
        ALTERNATIVES: 'alternatives',
        SALE_BY_LATEST: 'sale-by-latest',
        SALE_BY_POPULAR: 'sale-by-popular',
        LATEST: 'latest',
        POPULAR: 'popular',
        POPULAR_PRODUCTS_IN_CATEGORIES: 'popular-in-categories',
        POPULAR_PRODUCTS_IN_INTERESTED_CATEGORIES: 'popular-in-interested-categories',
        RECOMMENDED_PRODUCTS_IN_CATEGORIES: 'recommended-products-in-categories',
        PERSONAL: 'personal',
        SEARCH: 'search',
        RELATED: 'related',
        RELATED_PERSONAL: 'related-by-interested-properties',
        RELATED_WITHOUT_CATEGORIES: 'related-without-categories',
        RELATED_WITH_RULES: 'related-with-rules',
        VIEWED: 'viewed',
        COLLECTION: 'collection',
    };

    static TRACKING_EVENTS = {
        ADD_TO_BASKET: 'addToBasket',
        SEARCH: 'search',
        SET_CONTACT: 'setContact',
        RECOM_BLOCK_VIEWED: 'recomBlockViewed',
        RECOM_TAP: 'recomTap',
        RECOM_ADD_TO_BASKET: 'recomAddToBasket',
        WELCOME_SEQUENCE: 'welcomeSequence',
    };

    /**
     * Returns configuration for the fetch function
     * @private
     * @param {{ [param: string]: any }|undefined|null} data if provided changes method to POST and is sent as JSON
     * @returns {RequestInit}
     */
    getRequestInit (data) {
        const requestInit = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'accept': 'application/json'
            },
        };

        if (data) {
            requestInit.method = 'POST';
            requestInit.body = JSON.stringify(data);
        }

        return requestInit;
    }

    /**
     * Private method.
     * Generic method to query data from API
     * 
     * @param {{
     *  [param: string]: any,
     *  type?: string,
     *  event?: string,
     *  categoryIds?: string,
     *  productIds?: string,
     *  searchPhrase?: string,
     *  collectionName?: string,
     * }} queryParams
     * @param {{ [param: string]: any }|undefined|null} data if provided, is sent as JSON via POST
     * @param {RequestInit|undefined} requestInit lets override any RequestInit parameters
     * 
     * @returns {Promise<Array<{
     *  [key: string]: any,
     *  url: string,
     *  title: string,
     *  preview_picture_url: string,
     *  price: number,
     *  old_price: number,
     *  product_id: number,
     *  is_our_brand: boolean,
     *  is_pickup_only: boolean,
     *  is_premium_product: boolean,
     * }>>}
     */
    async fetch (queryParams, data, requestInit) {
        const urlParams = new URLQueryParams(queryParams);
        const url = `${PrkProductSliderDataProvider.API_URL}?${urlParams.toString()}`;

        try {
            const response = await fetch(url, Object.assign(this.getRequestInit(data), requestInit));
            if (!response.ok)
                throw new Error('Failed fetching product recommendations (frontend)');

            // No Content
            if (response.status === 204)
                return null;

            return await response.json();
        } catch (error) {
            let shouldLogError = true;

            // Skip logging firefox abort signals,
            // connection and validation errors.
            // Also skip nginx 499 (Client Closed Request)
            // and Cloudflare 520 (Web Server Returned an Unknown Error)
            if (error.name === 'AbortError' || /Code (5((0[2-4])|(2[1-4]))|422|499|520)/i.test(error))
                shouldLogError = false;

            if (shouldLogError) {
                logError(error, {
                    tags: { where: 'PrkProductSliderDataProvider' },
                    extra: { url, queryParams, data, requestInit },
                });
            }
            
            return null;
        }
    }
}


window.prkProductSliderDataProvider = new PrkProductSliderDataProvider();
export default PrkProductSliderDataProvider;
