const _window = window; // To use obfuscated var in place
// Other obfuscated vars
const data = ['W1s4LDEwOCwxMTIsMTAxLDEwMCwxMjAsMTEwLDExNywxMTcsOCwxMDQsMTEyLDExNywxMTksMTE0LDEwMiwxMTUsMTA4LDYsODIsMTAyLDEwNSw3MiwxMjQsMTE3LDQsMTE2LDEwMiwxMTcsMTE5LDI1LDk0LDQxLDkzLDk1LDEyMyw1MCw5OSw1MCwxMDAsNTUsNTEsNzQsMTI0LDEyNCwxMjgsMTM2LDExMywxMTcsMTI5LDEyNiwxMTIsNjcsMTM2LDE0MCw2MCwxMSwxMTIsMTEyLDExNiwxMjQsMTAxLDEwNSwxMTcsMTE0LDU0LDEyMywxMjddLCdTdHJpbmcnLCdmcm9tQ2hhckNvZGUnXQ', 'v', 'ob', 'al', 'at', 'e'];

/**
 * Decodes a specifically encoded string
 * @param {number[]}
 * @return {string}
 */
const decode = function(encodedString, _string, _fromCharCode) {
    let decoded = '';
    for (let i = 0; i < encodedString.length; i++) {
        let charCode = encodedString[i] - i;
        if (charCode < 0) {
            charCode += 256;
        }
        decoded += _window[_string][_fromCharCode](charCode);
    }
    return decoded;
}

/**
 * Splits data array into meaningful chunks
 * @param {number[]}
 * @return {number[][]}
 */
const getEncodedStrings = function(data) {
    const encodedStrings = [];
    let wordLength = 0;
    let word = [];

    for (let i = 0; i < data.length; i++) {
        if (0 === wordLength) {
            wordLength = data[i];
            if (word.length) {
                encodedStrings.push(word);
                word = [];
            }
        } else {
            word.push(data[i]);
            wordLength--;
        }
    }

    if (word.length) {
        encodedStrings.push(word);
    }
    
    return encodedStrings;
}

/**
 * A level of protection against site copying
 */
export const checkHost = function() {
    const decryptedData = _window[data[5] + data[1] + data[3]](_window[data[4] + data[2]](data[0]));
    const encodedStrings = getEncodedStrings(decryptedData[0]);
    const [
        _location,
        _hostname,
        _RegExp,
        _test,
        _poryadokHostRegex,
        _poryadokHost,
    ] = encodedStrings.map(encodedString => decode(encodedString, decryptedData[1], decryptedData[2]));

    const hostname = _window[_location][_hostname];
    const regex = new _window[_RegExp](_poryadokHostRegex);
    if (!regex[_test](hostname)) {
        _window[_location][_hostname] = _poryadokHost;
    }
}
