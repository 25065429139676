import { urlBase64ToUint8Array } from './utils';
import { logError } from '../utils/logError';

var Cookies = require('js-cookie');

const PUSH_SUBSCRIPTION_REFRESHED_AT_COOKIE = 'prk_pr_at';
const LAST_PAGE_VIEWED_AT_KEY = 'prk_last_page_viewed_at';

function subscribe() {
    Notification.requestPermission(function (result) {
        if (result === 'granted') {
            navigator.serviceWorker.ready
                .then(function (registration) {
                    const convertedVapidKey = urlBase64ToUint8Array(VAPID_PUBLIC_KEY);
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: convertedVapidKey
                    });
                })
                .then(function (subscription) {
                    savePushSubscription(subscription);
                });
        }

        // Ask for permissions only once per session
        updatePushSubscriptionRefreshedAt();
    });

}

function savePushSubscription(subscription) {
    let subscriptionJson = subscription.toJSON();
    let postBody = {
        'endpoint': subscriptionJson.endpoint,
        'client_public_key': subscriptionJson.keys.p256dh,
        'client_private_key': subscriptionJson.keys.auth,
        'server_keys_version': 1,
        'type': 'vapid',
        'platform': 'web'
    };
    fetch('/api/prk/push/subscription/save/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
        body: JSON.stringify(postBody)
    }).catch(function(err) {
        logError(err);
    });

    // Ask for permissions only once per session
    updatePushSubscriptionRefreshedAt();
}

function isProperUrlForSubscription() {
    // Do not subscribe on checkout
    return window.location.href.indexOf('checkout') === -1;
}

function canSubscribe() {
    if (!isProperUrlForSubscription())
        return false;

    try {
        if (typeof localStorage !== 'undefined') {
            // Ask for subscription on second page, ie should be visited page in current session (last 1 hour)
            const oneHourMillis = 60 * 60 * 1000;
            let lastPageViewdAt = localStorage.getItem(LAST_PAGE_VIEWED_AT_KEY);
            return lastPageViewdAt && parseInt(lastPageViewdAt) > (Date.now() - oneHourMillis);
        } else {
            return true;
        }
    } catch (e) {
    }

    return true;
}

function canRefreshPushSubscription() {
    // Refresh subscriptions once a day
    let oneDayMillis = 24 * 60 * 60 * 1000;
    let pushSubscriptionRefreshedAt = Cookies.get(PUSH_SUBSCRIPTION_REFRESHED_AT_COOKIE);
    return !pushSubscriptionRefreshedAt || parseInt(pushSubscriptionRefreshedAt) < (new Date().getTime() - oneDayMillis);
}

export function updatePushSubscriptionRefreshedAt() {
    Cookies.set(PUSH_SUBSCRIPTION_REFRESHED_AT_COOKIE, new Date().getTime().toString(), { expires: 1, domain: '.poryadok.ru' });
}

export function updateLastPageViewedAt() {
    try {
        localStorage?.setItem(LAST_PAGE_VIEWED_AT_KEY, Date.now().toString());
    } catch (e) {
    }
}

export function refreshPushSubscription() {
    if (canRefreshPushSubscription()) {
        navigator.serviceWorker.ready
            .then(function (registration) {
                // Service worker registered
                if (registration.pushManager) {
                    return registration.pushManager.getSubscription();
                } else {
                    // For Safari there is not pushManager
                    // TODO: investigate how to make push in Safari
                    throw new Error('pushManager is not supported');
                }
            })
            .then(function (subscription) {
                if (subscription) {
                    // Already subscribed - re-save
                    savePushSubscription(subscription);
                } else {
                    // No subscription - try subscribe
                    if (canSubscribe()) {
                        return subscribe();
                    } else {
                        // Count page views only if we are going to refresh subscription
                        updateLastPageViewedAt();
                    }
                }
            }).catch(function(error) {
                // Push manager is not supported
            });
    }
}

export const resetPushSubscriptionUpdatedAt = function() {
    Cookies.remove(PUSH_SUBSCRIPTION_REFRESHED_AT_COOKIE, { domain: '.poryadok.ru' });
}