import { $ } from 'dom7';

/**
 * Clones nodes or elements
 * @this {Dom7}
 * @returns {Dom7}
 */
export const clone = function () {
    return $(this.map(element => element.cloneNode(true)));
};

export default clone;