import { ready as genericReady } from './../../utils/ready';

/**
 * Initiates callback after DOM is ready
 * @this {Dom7}
 * @param {Function} callback
 * @returns {Dom7}
 */
export const ready = function (callback) {
    genericReady.call(this, callback);
    return this;
};

export default ready;