import { loadStyleAsync } from '../../utils/loadResourceAsync';
import Swiper, { Lazy } from 'swiper';
import Viewport from './../../common/viewport';
import { throttle } from './../../decorators';
Swiper.use([Lazy]);

export class PrkSlider {
    /**
     * Ensures that required stylesonly get loaded once.
     * @var {{[key: string]: Promise<void>}}}
     */
    static loadStylePromises = {};

    static DEFAULT_SWIPER_OPTIONS = {
        watchSlidesVisibility: true,
        preloadImages: false,
        lazy: {
            loadOnTransitionStart: true,
            checkInView: true
        },
        createElements: false,
        breakpointsBase: 'container',
        spaceBetween: 20,
        slidesPerView: 2,
        touchMoveStopPropagation: true,
        threshold: 5,
    };

    static DEFAULT_SLIDES_TO_LOAD = 6;

    static DEFAULT_OPTIONS = {
        dataProvider: null,
        additionalStyles: [],
        swiperOptions: PrkSlider.DEFAULT_SWIPER_OPTIONS,
        arrows: true,
        slidesToLoad: PrkSlider.DEFAULT_SLIDES_TO_LOAD
    };

    /**
     * @param {HTMLElement} container
     * @param {{
     *  dataProvider: any|null,
     *  additionalStyles?: string[],
     *  swiperOptions?: {[key: string]: any},
     *  arrows?: boolean,
     *  slidesToLoad?: number
     * }} options
     */
    constructor (container, options) {
        if (container instanceof HTMLElement) {
            this.container = container; // HTMLElement
            this.slides = []; // HTMLElement[]
            this.eventListeners = {
                onTouchStart: this.onTouchStart.bind(this),
                onSlideChangeTransitionStart: this.onSlideChangeTransitionStart.bind(this),
                onSlideChangeTransitionEnd: this.onSlideChangeTransitionEnd.bind(this),
                onBreakpoint: this.onBreakpoint.bind(this),
                onArrowClick: this.onArrowClick.bind(this),
                onTouchStartFreeMode: this.onTouchStartFreeMode.bind(this),
                customLazyLoad: throttle(this.customLazyLoad.bind(this), 200),
                
            };
            this.isAllSlidesLoaded = false;
            this.isLoadingNextSlides = false;
            this.slideTransitionSpeed = 800;
            this.arrows = []; // Make sure at least empty arrows array is present on the instance
            
            // Have to do it like so in order to prevent overwriting.
            // Also have to provide virtual.slides for several instances of sliders to have separate slide arrays
            options.swiperOptions = Object.assign({}, PrkSlider.DEFAULT_SWIPER_OPTIONS, options.swiperOptions);

            this.options = Object.assign({}, PrkSlider.DEFAULT_OPTIONS, options);
            this.dataProvider = this.options.dataProvider;
            this.initialize();
        }
    }

    /**
     * Initializes PrkSlider
     * @private
     */
    initialize () {
        this.readyPromise = new Promise(async (resolve, reject) => {
            try {
                // Mark slider as initilizing/initialized.
                // Not to initialize a slider twice, use :not(.is-initializing):not(.is-initialized) selector
                this.container.classList.add('is-initializing');

                // Pickup prerendered slides
                this.slides.push(...this.pickupRenderedSlides());

                const [ styles, slides ] = await this.loadResources();

                // In case angular or some other code destroyed PrkSlider component during loading of resources
                // Rejecting with null should not result in logging as an error
                if (!this.container) return reject(new Error('Prk slider container element has gone'));

                this.container.innerHTML = '';
                this.renderVirtualSlides(slides);

                // If there are any slides
                if (this.slides.length) {
                    this.renderSwiper();
                    this.afterSliderInit();
                    this.updateArrowsEnabledState();
                }
                resolve(this);
            }
            catch (error) {
                reject(error);
            }
            finally {
                this.container?.classList.remove('is-initializing');
                this.container?.classList.add('is-initialized');
            }
        });
    }

    /**
     * Loads required styles.
     * Already loaded styles are ignored.
     * @private
     * @return {Promise<void>}
     */
    loadStyles () {
        const stylesToLoad = this.options.additionalStyles;
        const promises = stylesToLoad.map(url => {
            PrkSlider.loadStylePromises[url] = PrkSlider.loadStylePromises[url] || loadStyleAsync(url);
            return PrkSlider.loadStylePromises[url];
        });
        return Promise.all(promises);
    }

    /**
     * Loads initial slides from backend-rendered ones or by ajax call
     * @private
     * @returns {Promise{string|object[]}}
     */
    loadInitialSlides () {
        // When initial slides are prerendered,
        // do not load more initially
        if (this.slides.length)
            return Promise.resolve([]);

        // Else asyncronously load slides
        return this.loadNextSlides();
    }

    /**
     * Loads all the nesessary resources
     * @private
     * @returns {[Promise<string|null>[], Promise<string|object[]>, Promise<typeof Swiper>]}
     */
    loadResources () {
        return Promise.all([
            this.loadStyles(),
            this.loadInitialSlides()
        ]);
    }

    /**
     * Override this method to add custom logic
     * right after Swiper slider initializes
     * @override
     * @protected
     */
    afterSliderInit () {}

    /**
     * Override this method to add custom logic
     * right after new slides are rendered
     * @override
     * @protected
     */
    afterSlidesRendered () {
        // Fix never lazy loaded images on duplicate slides
        // while in loop mode.
        // Happens when slide is out of view
        // but still gets swiper-lazy-loading class after appendSlide
        for (const slide of this.duplicateSlides) {
            if (!this.viewport.isVisible(slide)) {
                const lazyLoadingImg = slide.querySelector('.swiper-lazy-loading')
                lazyLoadingImg?.classList.remove('swiper-lazy-loading');
            }
        }
    }

    /**
     * Returns count of slides to render when slidesPerView is 'auto'
     * @override
     * @protected
     * @returns {number>}
     */
    getSlidesToRenderCount () {
        return this.options.slidesToLoad || PrkSlider.DEFAULT_SLIDES_TO_LOAD;
    }

    /**
     * This method should be overridden by extending class.
     * This method should return array of prerendered slides
     * @override
     * @protected
     * @return HTMLElement[] array of prerendered slides
     */
    pickupRenderedSlides () {
        return [];
    }

    /**
     * Renders a slide from data object
     * @override
     * @protected
     * @param {{
     *  [key: any]: any
     *  imageUrl: string
     * }} slideData
     * @returns {HTMLElement}
     */
    renderSlide (slideData) {
        const slide = document.createElement('img');
        slide.classList.add('swiper-lazy');
        slide.dataset.src = slideData.imageUrl;
        return slide;
    };

    /**
     * Loads next slides from data provider
     * @protected
     * @override
     * @param {boolean} shouldLoadAllSlides
     * @return {Prmomise<object[]>}
     */
    async loadNextSlidesFromDataProvider (shouldLoadAllSlides) {
        return [];
    }

    /**
     * Loads slides from api
     * @protected
     * @param {boolean|undefined} shouldLoadAllSlides pass TRUE if you want to load all the slides at once
     * @return {Promise<string|object[]>}
     */
    loadNextSlides (shouldLoadAllSlides) {
        if (this.isAllSlidesLoaded) return Promise.resolve('All slides has loaded');
        if (this.isLoadingNextSlides) return Promise.resolve('Is still loading new slides');

        this.isLoadingNextSlides = true;
        this.container.classList.add('is-loading-slides');
        this.updateArrowsEnabledState();
        return new Promise(async resolve => {
            try {
                resolve(await this.loadNextSlidesFromDataProvider(shouldLoadAllSlides));
            }
            catch (reason) {
                resolve(reason);
            }
            finally {
                // In case angular destroyed PrkSlider while promise pending state
                if (!this.container) return;

                this.isLoadingNextSlides = false;
                this.container.classList.remove('is-loading-slides');
                this.updateArrowsEnabledState();
            }
        });
    }

    /**
     * Resolves after initialization
     * or rejects on error
     * @public
     * @return {Promise<PrkSlider>}
     */
    get ready () {
        return this.readyPromise || Promise.reject(new Error('PrkSlider did not initialize'));
    }

    /**
     * Returns true if all the slides have been rendered
     * @public
     * @return {boolean}
     */
    get isAllSlidesRendered () {
        return this.isAllSlidesLoaded && this.slides.length === this.realSlides.length;
    }

    /**
     * Return Swiper params of current breakpoint
     * @public
     * @returns {SwiperBreakPointParamsObject}
     */
    get breakpointParams () {
        const swiper = this.swiper;
        const breakPointParams = (swiper && swiper.params && swiper.params.breakpoints) || this.options.swiperOptions.breakpoints || swiper.params || this.options.swiperOptions || {};
        const currentBreakpoint = (swiper && swiper.currentBreakpoint) || (() => {
            // If swiper isn't initialized yet,
            // calculate currentBreakpoint manually.
            // This code is meant to only run for renderInitialSlides method.
            const containerWidth = this.container.clientWidth;
            let currentBreakpoint = 0;
            for (const breakpoint in breakPointParams) {
                const numericBreakpoint = parseInt(breakpoint);
                if (typeof numericBreakpoint !== 'number' || isNaN(numericBreakpoint))
                    continue;

                if (numericBreakpoint < currentBreakpoint)
                    continue;

                if (numericBreakpoint > containerWidth)
                    continue;

                currentBreakpoint = numericBreakpoint;
            }
            return currentBreakpoint;
        })();
        return breakPointParams[currentBreakpoint] || {};
    }

    /**
     * Returns TRUE is swiper is set to loop mode
     * @public
     * @returns {boolean}
     */
    get isLoopMode () {
        return true === this.options.swiperOptions.loop;
    }

    /**
     * Returns true if loop mode is enabled
     * @protected
     * @returns {boolean}
     */
    get isLoopModeEnabled () {
        const isEnabled = this.swiper?.params.loop;
        return typeof isEnabled === 'undefined' // undefined before swiper initialization
            ? this.isLoopMode
            : isEnabled;
    }

    /**
     * Returns true if loop mode currently supported
     * @protected
     * @returns {boolean}
     */
    get canLoop () {
        if (!this.isLoopMode) return false;

        return typeof this.breakpointParams.slidesPerView === 'number' && this.breakpointParams.slidesPerView < this.slides.length;
    }

    /**
     * Returns true if loop mode shall be changed
     * @protected
     * @returns {boolean}
     */
    get shouldChangeLoopState () {
        return this.canLoop !== this.isLoopModeEnabled
    }

    /**
     * Returns currently active slide
     * @public
     * @returns {HTMLElement|null}
     */
    get activeSlide () {
        return (this.swiper && this.swiper.slides && this.swiper.slides[this.swiper.activeIndex]) || null;
    }

    /**
     * Returns index of currently active slide
     * @public
     * @returns {number}
     */
    get activeSlideIndex () {
        return (this.swiper && this.swiper.realIndex) || 0;
    }

    /**
     * Returns rendered in Swiper slides including duplicates
     * @public
     * @returns {HTMLElement[]}
     */
    get renderedSlides () {
        return this?.swiper?.slides ?? [];
    }

    /**
     * Returns only duplicate slides rendered in Swiper
     * @public
     * @returns {HTMLElement[]}
     */
    get duplicateSlides () {
        return this.renderedSlides.filter(slide => slide.classList.contains('swiper-slide-duplicate'));
    }

    /**
     * Returns rendered in Swiper slides excluding duplicates
     * @public
     * @returns {HTMLElement[]}
     */
    get realSlides () {
        return this.renderedSlides.filter(slide => !slide.classList.contains('swiper-slide-duplicate'));
    }

    /**
     * Returns indexes of slides to render next page
     * @public
     * @return {{ start: number, end: number }}
     */
    get nextPageSlideIndexes () {
        const start = this.realSlides.length,
              end   = Math.min(start + this.slidesToRender, this.slides.length);
        return { start, end };
    }

    /**
     * Returns true if it's time to load next pack of slides
     * @public
     * @returns {boolean}
     */
    get shouldLoadNextSlides () {
        return this.activeSlideIndex + this.slidesToLoad - 1 >= this.slides.length;
    }

    /**
     * Returns true if it's time to render next pack of slides
     * @public
     * @returns {boolean}
     */
    get shouldRenderNextSlides () {
        return this.activeSlideIndex + this.slidesToRender < this.slides.length;
    }

    /**
     * Returns number of slides to load
     * @public
     * @returns {number}
     */
    get slidesToLoad () {
        return this.options.slidesToLoad;
    }

    /**
     * Returns number of slides to render
     * @public
     * @returns {number}
     */
    get slidesToRender () {
        if (isNaN(this.breakpointParams.slidesPerView)) {
            return this.getSlidesToRenderCount() || this.slidesToLoad;
        }
        return this.breakpointParams.slidesPerView;
    }

    /**
     * Initializes Swiper.
     * @private
     */
    renderSwiper () {
        this.swiperElementWrap = document.createElement('div');
        this.swiperElementWrap.className = 'prk-slider__swiper-wrap';
        this.swiperElement = document.createElement('div');
        this.swiperElement.className = 'prk-slider__swiper';
        this.swiperWrapper = document.createElement('div');
        this.swiperWrapper.className = 'swiper-wrapper';

        if (this.options.arrows)
            this.renderNavigationArrows();

        this.renderInitialSlides();

        this.swiperElement.appendChild(this.swiperWrapper);
        this.swiperElementWrap.appendChild(this.swiperElement);
        this.container.appendChild(this.swiperElementWrap);

        try {
            this.initSwiper({ loop: this.canLoop, virtualTranslate: !this.canLoop });
        } catch (error) {
            if (/\.remove is not a function/.test(error.message) && this.canLoop) {
                // In older versions of Firefox creating a loop sometimes
                // throws an error in createLoop.js. Looks like some
                // DOM handling routing is not on time.
                // Rerendering swiper without loop and with loop again
                // somehow solves the problem.
                this.reinitSwiper({ loop: false, virtualTranslate: true });
                this.reinitSwiper({ loop: this.canLoop, virtualTranslate: !this.canLoop });
            }
        }
    }

    /**
     * Initializes Swiper
     * @private
     * @param {SwiperOptions} newOptions
     */
    initSwiper (newOptions) {
        this.swiper = new Swiper(this.swiperElement, Object.assign({}, this.options.swiperOptions, newOptions || {}));
        this.viewport = new Viewport(this.swiper.wrapperEl);
        if (newOptions.virtualTranslate === true) {
            this.swiper.wrapperEl?.classList.add('hide-scrollbar');
            this.swiper.wrapperEl.addEventListener('touchstart', this.eventListeners.onTouchStartFreeMode);
            this.swiper.wrapperEl.addEventListener('touchmove', this.eventListeners.customLazyLoad);
            this.swiper.wrapperEl.addEventListener('touchend', this.eventListeners.customLazyLoad);
            this.swiper.on('breakpoint', this.eventListeners.onBreakpoint);
        } else {
            this.swiper.wrapperEl?.classList.remove('hide-scrollbar');
            this.swiper.on('slideChangeTransitionStart', this.eventListeners.onSlideChangeTransitionStart);
            this.swiper.on('slideChangeTransitionEnd', this.eventListeners.onSlideChangeTransitionEnd);
            this.swiper.on('touchStart', this.eventListeners.onTouchStart);
            this.swiper.on('breakpoint', this.eventListeners.onBreakpoint);
        }

        this.afterSlidesRendered();
    }

    /**
     * Reinitializes Swiper
     * @protected
     * @param {SwiperOptions} newOptions
     */
    reinitSwiper (newOptions) {
        this.destroySwiper();
        this.initSwiper(newOptions);

        setTimeout(() => {
            this.swiper.lazy.load();
            this.updateArrowsEnabledState();
        });
    }

    /**
     * Renders navigation arrows.
     * @private
     */
    renderNavigationArrows () {
        const arrowPrev = document.createElement('button');
        arrowPrev.className = 'prk-slider__arrow prk-slider__arrow_prev';

        const arrowNext = document.createElement('button');
        arrowNext.className = 'prk-slider__arrow prk-slider__arrow_next';

        this.arrows = [arrowPrev, arrowNext];

        for (const arrow of this.arrows) {
            arrow.addEventListener('click', this.eventListeners.onArrowClick);
            this.swiperElementWrap.appendChild(arrow);
        }
    }

    /**
     * Virtually render slides
     * @private
     * @param {object[]} slidesData
     */
    renderVirtualSlides (slidesData) {
        if (!(slidesData instanceof Array)) return;

        for (const slideData of slidesData) {
            const slide = this.renderSlide(slideData);
            this.slides.push(slide);
        }

        if (this.swiper && this.shouldChangeLoopState)
            this.reinitSwiper({ loop: this.canLoop });
    }

    /**
     * Renders slides for the first page
     * @private
     */
    renderInitialSlides () {
        for (const slide of this.slides.slice(this.activeSlideIndex, this.activeSlideIndex + this.slidesToRender)) {
            this.swiperWrapper.appendChild(slide);
        }
    }

    /**
     * Renders next slides for swiping, clicking on "next" arrow
     * @private
     * @param {boolean|undefined} renderAllSlides if TRUE renders all the virtual slides
     */
    renderNextSlides (renderAllSlides) {
        if (!this.slides.length) return;
        if (this.realSlides.length - this.slidesToRender > this.activeSlideIndex) return; // If not last rendered page, return

        const { start, end } = this.nextPageSlideIndexes;
              
        if (start < end) {
            this.swiper.appendSlide(this.slides.slice(start, renderAllSlides ? undefined : end));

            this.afterSlidesRendered();

            this.swiper.update();
            this.swiper.lazy.load();
        }
    }

    /**
     * Custom slide visibility check for lazyload
     * that accounts for scroll deceleration animation
     * @private
     */
    customLazyLoad () {
        // In case angular destroyed PrkSlider
        // while timeouting for scroll decelleration animation
        if (!this.container) return;

        const currentViewportPosition = this.viewport.getViewport(),
              slidesToLoadImage = [];

        // First check what slides are visible and save their indexes.
        // Its better to modify the DOM (load images) afterwards for performance reasons.
        const renderedSlides = this.renderedSlides;
        for (let i = 0; i < renderedSlides.length; i++) {
            if (this.viewport.isVisible(renderedSlides[i]))
                slidesToLoadImage.push(i);
        }

        // Load images of the slides that are visible
        for (const slideIndex of slidesToLoadImage) {
            this.swiper.lazy.loadInSlide(slideIndex);
        }

        // If scroll position has changed since processing the last event
        // recheck slides visibility every 200 to account for scroll decelleration animation
        if (!this.previousViewportPosition || this.previousViewportPosition.left !== currentViewportPosition.left)
            setTimeout(this.eventListeners.customLazyLoad, 200);

        this.previousViewportPosition = currentViewportPosition;
    }

    /**
     * Handles touchStart event of Swiper
     * @private
     */
    async onTouchStartFreeMode () {
        this.swiper.wrapperEl.removeEventListener('touchstart', this.eventListeners.onTouchStartFreeMode);

        // Render already loaded (during initialization) but not rendered slides
        this.swiper.appendSlide(this.slides.slice(this.realSlides.length));
        this.afterSlidesRendered();
        this.swiper.update();

        // Load all the slides
        const newSlidesData = await this.loadNextSlides(true);

        // In case angular destroyed PrkSlider during promise pending state
        if (!this.container) return;

        this.renderVirtualSlides(newSlidesData);

        // Render all of the slides
        this.swiper.appendSlide(this.slides.slice(this.realSlides.length));
        this.afterSlidesRendered();
        this.swiper.update();
        this.swiper.lazy.load();
    }

    /**
     * Handles touchStart event of Swiper
     * @private
     */
    async onTouchStart () {
        // If Swiper is in loop mode, load and render all the slides
        if (this.isLoopModeEnabled) {
            const newSlidesData = await this.loadNextSlides(true);

            // In case angular destroyed PrkSlider during promise pending state
            if (!this.container) return;

            this.renderVirtualSlides(newSlidesData);
            this.renderNextSlides(true);
        }
        
        // If Swiper isn't in loop mode, check if next slides should be rendered
        else if (this.shouldRenderNextSlides) {
            this.renderNextSlides();
        }
    }

    /**
     * Handles slideChangeTransitionStart event of Swiper
     * @private
     */
    async onSlideChangeTransitionStart () {
        this.updateArrowsEnabledState();

        if (this.shouldLoadNextSlides) {
            const newSlidesData = await this.loadNextSlides();

            // In case angular destroyed PrkSlider during promise pending state
            if (!this.container) return;

            this.renderVirtualSlides(newSlidesData);
        }
    }

    /**
     * Handles slideChangeTransitionEnd event of Swiper
     * @private
     */
    async onSlideChangeTransitionEnd () {
        if (this.shouldLoadNextSlides) {
            const newSlidesData = await this.loadNextSlides();

            // In case angular destroyed PrkSlider during promise pending state
            if (!this.container) return;

            this.renderVirtualSlides(newSlidesData);
        }

        this.updateArrowsEnabledState();
    }

    /**
     * Handles breakpoint event of Swiper
     * @private
     */
    onBreakpoint () {
        this.renderNextSlides();

        // Use timeout to let Swiper handle all the routines for breakpoint change
        setTimeout(() => {
            if (this.swiper && this.shouldChangeLoopState)
                this.reinitSwiper({ loop: this.canLoop });
        }, 0);
    }

    /**
     * Custom function to handle clicking on navigation arrows
     * @private
     * @param {MouseEvent|TouchEvent|PointerEvent} event
     */
    async onArrowClick (event) {
        // Can't use event.currentTarget as it equals to null
        // because eventListener is attached to Swiper
        if (!(event.target instanceof HTMLElement)) return;
        if (!event.target.classList.contains('prk-slider__arrow')) return;

        // Button 'Next' click
        if (event.target.classList.contains('prk-slider__arrow_next') && (this.isLoopModeEnabled || this.shouldRenderNextSlides)) {
            this.renderNextSlides();
            this.swiper.slideNext(this.slideTransitionSpeed);
        }
        
        // Button 'Prev' click
        else if (event.target.classList.contains('prk-slider__arrow_prev') && (this.isLoopModeEnabled || 0 < this.activeSlideIndex)) {

            // If Swiper is in loop mode, load and render all the slides
            if (this.isLoopModeEnabled && this.activeSlideIndex <= this.slidesToRender) {
                const newSlidesData = await this.loadNextSlides(true);

                // In case angular destroyed PrkSlider during promise pending state
                if (!this.container) return;

                this.renderVirtualSlides(newSlidesData);
                this.renderNextSlides(true);
            }

            this.swiper.slidePrev(this.slideTransitionSpeed);
        }
    }

    /**
     * Custom function to update disabling of navigation arrows
     * @private
     */
    updateArrowsEnabledState () {
        for (const arrow of this.arrows) {
            if (arrow.classList.contains('prk-slider__arrow_prev')) {
                if (this.isLoopModeEnabled || this.activeSlideIndex > 0)
                    arrow.removeAttribute('disabled');
                else
                    arrow.setAttribute('disabled', 'disabled');
                
            } else {
                if (this.isLoopModeEnabled || this.isLoadingNextSlides || this.slides.length > this.activeSlideIndex + this.slidesToRender)
                    arrow.removeAttribute('disabled');
                else
                    arrow.setAttribute('disabled', 'disabled');
            }
        }
    }

    /**
     * Cleans up swiper
     * @protected
     */
    destroySwiper () {
        if (this.swiper && this.swiper.destroy instanceof Function) {
            this.swiper.wrapperEl.removeEventListener('touchstart', this.eventListeners.onTouchStartFreeMode);
            this.swiper.wrapperEl.removeEventListener('touchmove', this.eventListeners.customLazyLoad);
            this.swiper.wrapperEl.removeEventListener('touchend', this.eventListeners.customLazyLoad);
            this.swiper.off('touchStart', this.eventListeners.onTouchStart);
            this.swiper.off('slideChangeTransitionStart', this.eventListeners.onSlideChangeTransitionStart);
            this.swiper.off('slideChangeTransitionEnd', this.eventListeners.onSlideChangeTransitionEnd);
            this.swiper.off('breakpoint', this.eventListeners.onBreakpoint);
            this.swiper.destroy();
            this.swiper = null;
        }
    }

    /**
     * Cleans up
     * @public
     */
    destroy () {
        for (const arrow of this.arrows) {
            arrow.removeEventListener('click', this.eventListeners.onArrowClick);
        }

        this.destroySwiper();

        this.viewport = null;
        this.container = null;
        this.slides = null;
        this.eventListeners = null;
        this.arrows = null;
        this.options =null;
    }

}

export default PrkSlider;
