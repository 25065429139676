export class Chips {
    static CHIPS_CLASS = 'chips';
    static CHIPS_CLASS_INITIALIZED = `${Chips.CHIPS_CLASS}--initialized`;
    static CHIPS_CLASS_LIMITED_ITEMS = `${Chips.CHIPS_CLASS}--limited-items`;
    static CHIPS_CLASS_SHOW_LIMITED_ITEMS = `${Chips.CHIPS_CLASS}--show-limited-items`;

    static CHIPS_SHOW_MORE_BUTTON_CLASS = `${Chips.CHIPS_CLASS}__show-more-button`;
    static CHIPS_DEFAULT_SHOW_CAPTION = 'Показать все';
    static CHIPS_DEFAULT_HIDE_CAPTION = 'Скрыть';

    static CHIP_CLASS = 'chip';
    static CHIP_CAPTION_CLASS = `${Chips.CHIP_CLASS}__caption`;

    /**
     * @var {HTMLElement} _chipsElement
     */
    _chipsElement;

    /**
     * @var {HTMLElement|null} _showMoreButton
     */
    _showMoreButton = null;

    /**
     * @var {{[methodName: string]: EventListener}} _eventListeners
     */
    _eventListeners;

    /**
     * @param {HTMLElement} chipsElement 
     */
    constructor(chipsElement) {
        this._chipsElement = chipsElement;
        this._chipsElement.classList.add(Chips.CHIPS_CLASS_INITIALIZED);
        
        this._eventListeners = {
            toggleVisibility: this.toggleVisibility.bind(this),
        };

        this._init();
    }

    _init() {
        if (!this._chipsElement.classList.contains(Chips.CHIPS_CLASS_LIMITED_ITEMS))
            return;

        this._showMoreButton = this._chipsElement.querySelector(`.${Chips.CHIPS_SHOW_MORE_BUTTON_CLASS}`);

        if (!this._showMoreButton)
            return;

        this._showMoreButton.addEventListener('click', this._eventListeners.toggleVisibility);
    }

    /**
     * @param {MouseEvent|TouchEvent|PointerEvent} event 
     */
    toggleVisibility(event) {
        event.preventDefault();
        if (this._chipsElement.classList.contains(Chips.CHIPS_CLASS_SHOW_LIMITED_ITEMS))
            this._hideChips();
        else
            this._showChips();
    }

    _showChips() {
        this._chipsElement.classList.add(Chips.CHIPS_CLASS_SHOW_LIMITED_ITEMS);
        const showMoreButtonCaption = this._showMoreButton.querySelector(`.${Chips.CHIP_CAPTION_CLASS}`);
        
        if (showMoreButtonCaption)
            showMoreButtonCaption.textContent = Chips.CHIPS_DEFAULT_HIDE_CAPTION;
    }

    _hideChips() {
        this._chipsElement.classList.remove(Chips.CHIPS_CLASS_SHOW_LIMITED_ITEMS);
        const showMoreButtonCaption = this._showMoreButton.querySelector(`.${Chips.CHIP_CAPTION_CLASS}`);
        
        if (showMoreButtonCaption)
            showMoreButtonCaption.textContent = Chips.CHIPS_DEFAULT_SHOW_CAPTION;
    }
}

export const initChips = function(event) {
    for (const chips of [...document.querySelectorAll(`.${Chips.CHIPS_CLASS}:not(.${Chips.CHIPS_CLASS_INITIALIZED})`)]) {
        new Chips(chips);
    }
}