import { width as dom7Width } from 'dom7';

/**
 * Allow function to act as a setter
 * @this {Dom7}
 * @param {string|number|undefined} value if value is provided, acts as setter, otherwise as getter.
 * @returns {Dom7}
 */
export const width = function (value) {
    if (typeof value === 'string')
        return this.css('width', value);

    if (typeof value === 'number')
        return this.css('width', `${value}px`);

    return dom7Width.call(this);
};

export default width;