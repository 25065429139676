import { height as dom7Height } from 'dom7';

/**
 * Allow function to act as a setter
 * @this {Dom7}
 * @param {string|number|undefined} value if value is provided, acts as setter, otherwise as getter.
 * @returns {Dom7}
 */
export const height = function (value) {
    if (typeof value === 'string')
        return this.css('height', value);

    if (typeof value === 'number')
        return this.css('height', `${value}px`);

    return dom7Height.call(this);
};

export default height;