import { handleModuleLoadingError } from './handleModuleLoadingError.js';

/**
 * @param {boolean} customErrorHandling pass TRUE to use custom error handling
 * @return {Promise<PrkModal>}
 */
export const loadPrkModal = (customErrorHandling) => {
    const promise = import('/js/prk/prk-modal').then(m => m.default);

    if (!customErrorHandling)
        promise.catch(handleModuleLoadingError);

    return promise;
};
