import Clock from "./clock";

export class Countdown extends Clock {
  /**
   * @param {HTMLElement} element
   * @param {Date} time
   */
  constructor(element, time) {
    super(element, time);
    this._element.classList.add('countdown');
  }

  /**
   * Counts time backwards and stops when reaching 00:00:00
   * @inheritdoc
   * @override
   */
  tick() {
    this._time = new Date(this._time.getTime() - 1000);
    this.render();

    // Stop countdown when reaching 00:00:00
    const { hours, minutes, seconds } = this.getTime();
    if (0 === hours && 0 === minutes && 0 === seconds)
      clearInterval(this._tickTimer);
  }
}
