import * as Sentry from '@sentry/browser';

/**
 * An invalid or illegal string was specified in gtag.js
 * @param {Sentry.Event} event 
 * @returns {boolean}
 */
const isGtagIllegalStringError = function(event) {
    if (
        event.exception &&
        event.exception.values instanceof Array &&
        event.exception.values.length &&
        event.exception.values[0].value === 'An invalid or illegal string was specified' &&
        event.exception.values[0].stacktrace &&
        event.exception.values[0].stacktrace.frames instanceof Array &&
        event.exception.values[0].stacktrace.frames.length &&
        /gtag/.test(event.exception.values[0].stacktrace.frames[0].filename)
    ) {
        return true;
    }

    return false;
}

/**
 * When plyr fires CustomError with type 'error' browsers don't like it, just skip
 * @param {Sentry.Event} event 
 * @returns {boolean}
 */
const isPlyrCustomError = function(event) {
    if (
        event.exception &&
        event.exception.values instanceof Array &&
        event.exception.values.length &&
        event.exception.values[0].value === 'Event `CustomEvent` (type=error) captured as exception' &&
        event.extra &&
        event.extra.__serialized__ &&
        event.extra.__serialized__.detail &&
        event.extra.__serialized__.detail.plyr
    ) {
        return true;
    }

    return false;
}

/**
 * Initializes Sentry
 * @param {string|undefined} environment environment
 * @param {string|undefined} release release version like git commit hash
 * @returns {void}
 */
export const initSentry = function(environment, release) {
    const Cookies = require('js-cookie');
    const cartGuid = Cookies.get('BITRIX_SM_SALE_UID');
    const cityCode = Cookies.get('CITY_CODE');

    const user = cartGuid
        ? { cart_guid: cartGuid }
        : undefined;

    const location = cityCode
        ? { city_code: cityCode }
        : undefined;

    window.Sentry = Sentry; // Make Sentry awailable to angular and inline scripts
    Sentry.init({
        dsn: ['production', 'prod'].includes(environment)
            ? 'https://a79a68c4e8a03a794f1262717a244eb1@sentry.poryadok.ru/8'
            : 'https://ad8cc40341e262aecd707802a1e11dc2@sentry.poryadok.ru/9',
        environment,
        release: ['development', 'develop', 'dev'].includes(environment)
            ? undefined
            : release, // Only provide release for staging and prod environment
        normalizeDepth: 10, // Allow deeper objects be passed to Sentry without truncating into either 'Object' or 'Array'
        initialScope: {
            tags: {
                application: 'js',
                'cart_guid': cartGuid,
                'location.city_code': cityCode,
            },
            contexts: { 
                User: user,
                location,
            },
        },
        integrations: [
            Sentry.contextLinesIntegration(),
        ],
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            'Can\'t find variable: ZiteReader',
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            // Avast extension error
            '_avast_submit',
            // Network errors such as going offline or being blocked by a proxy
            // or when user navigates away to another page or closes tab/window
            // @see https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
            'Failed to fetch',
            'ChunkLoadError',
            'TypeError: NetworkError when attempting to fetch resource.',
            'TypeError: Сетевое соединение потеряно.',
            'TypeError: Вероятно, соединение с Интернетом прервано.',
            'TypeError: cancelled',
            'TypeError: отменено',
            // Wrong querySelector, usually on iOS Yandex browser. Seems to be browser specific
            'SyntaxError: The string did not match the expected pattern.',
            // See https://github.com/getsentry/sentry-javascript/issues/3040#issuecomment-913549441
            'undefined is not an object (evaluating \'window.webkit.messageHandlers\')',
            // Yandex metrika tries to get battery info
            'Failed to execute \'getBattery\' on \'Navigator\': Illegal invocation',
            // Can safely ignore ResizeObserver loop limit exceeded errors
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications',
            // Cannot catch, probably a browser pluging is causing it
            '(intermediate value)(...) is not a function',
            // Not found temporary callback functions of some browser extensions
            /^window\.cb[\d]+ is not a function$/i,
            // Mute Ya.Sovetnik errors as it happens in browser addons
            'yandex.sovetnik',
            // Fetch failed due to a reason, most likely internet connection error
            'Load failed',
            // Request or an async task cancelled
            'AbortError',
            // Error in new versions of Yandex Browser
            'crypto.randomUUID is not a function'
        ],
        denyUrls: [
            // Google Adsense
            /pagead\/js/i,
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome(-extension)?:\/\//i,
            // Firefox extensions
            /^resource:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            /polyfill\.io/i,
            // Yandex services
            /^https?:\/\/(api-maps|mc)\.yandex\.ru\//i,
            /^https?:\/\/yastatic\.net\//i,
            // Bitrix live chat widget
            /^https?:\/\/poryadok\.bitrix24\.ru\//i
        ],
        beforeSend(event) {
            if (isGtagIllegalStringError(event))
                return null;

            if (isPlyrCustomError(event))
                return null;
            
            return event;
        }
    });
}
