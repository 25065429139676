// Always include common JS to page-specific
import '/js/common/index';

import {
    initButtons,
    initIndexSlider,
    initSubscribeEmailForm,
    initSubscribeEmailHandler,
    initTodayOnlyCountdown
} from './functions';
import {initPicturesWithPinsSlider} from "../pictures-with-pins";

$(document).ready(() => {
    initButtons();
    initIndexSlider();
    initTodayOnlyCountdown();
    initSubscribeEmailForm();
    initSubscribeEmailHandler();
    initPicturesWithPinsSlider();
});