import { getVendorPrefixedStyle } from './../getVendorPrefixedStyle';

/**
 * Centers image inside of container.
 * @todo Think about it
 */
export function centerImageInsideOfContainer() {
    /** @var string */
    let transformStyle;

    if (this.naturalWidth < this.naturalHeight) {
        this.style.width = '100%';
        this.style.height = 'auto';
        this.style.top = '50%';
        this.style.left = '';
        transformStyle = 'translate3d(0, -50%, 0)';
    } else {
        this.style.height = '100%';
        this.style.width = 'auto';
        this.style.top = '';
        this.style.left = '50%'
        transformStyle = 'translate3d(-50%, 0, 0)';
    }

    this.style.position = 'relative';

    for (const transform of getVendorPrefixedStyle('transform')) {
        this.style[transform] = transformStyle;
    }
}
