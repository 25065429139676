const unescapeMap = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#039;': "'",
  '&apos;': "'",
};

const searchPattern = new RegExp(Object.keys(unescapeMap).map(key => `(${key})`).join('|'), 'g');

/**
 * @param {string} text
 * @returns {string}
 */
export const unescapeHTML = function (text) {
  return text.replace(searchPattern, substring => unescapeMap[substring]);
}

export default unescapeHTML;
