import { data as dom7Data, dataset as dom7Dataset } from 'dom7';

/**
 * Workaround function to get values from data attibutes
 * not only by kebab-cased keys but also by camelCased keys.
 * @this {Dom7}
 * @param {string} key
 * @param {any|undefined} value if value is provided, acts as setter, otherwise as getter
 * @returns {Dom7}
 */
export const data = function (key, value) {
    const result = dom7Data.call(this, key, value);
    if (typeof value === 'undefined' && typeof result === 'undefined' && this.length) {
        return dom7Dataset.call(this)[key];
    }

    return result;
};

export default data;