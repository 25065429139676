import { loadPrkCollapsible } from '../../async/loadPrkCollapsible.js';
import {showLoader, hideLoader} from "../../globalFunctions";

const INITIALIZED_CLASS = 'is-initialized';
const TARGET_CLASS      = 'prk-collapsible__header';
const CONFIG_DATASET    = 'config';

export const prkCollapsibleConfigs = {};

/**
 * A generic function to be attached on document
 * that initializes a prk-collapsibles
 * @param {MouseEvent|TouchEvent|PointerEvent} event
 * @returns {void}
 */
export const handleClickOnPrkCollapsible = async function (event) {
    let button = event.target;

    // For the case a button contains an element/elements
    while (!button.classList.contains(TARGET_CLASS) && button.parentElement) {
        button = button.parentElement;
    }

    if (!button.classList.contains(TARGET_CLASS)) return;

    const collapsibleElement = button.parentElement,
          configKey          = collapsibleElement.dataset[CONFIG_DATASET],
          config             = prkCollapsibleConfigs[configKey];

    if (collapsibleElement.classList.contains(INITIALIZED_CLASS)) return;

    // Show loader only on slow connection
    let loaderTimeout = setTimeout(showLoader, 200);
    const PrkCollapsible = await loadPrkCollapsible();
    clearTimeout(loaderTimeout);
    loaderTimeout = null;
    hideLoader();

    (new PrkCollapsible(collapsibleElement, config)).toggle();
}
