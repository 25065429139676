import mergeOptions from 'merge-options';
import { captureException } from '@sentry/browser';
import { ErrorWithContext } from '../../error-with-context';

/**
 * Logs error to Sentry
 * @link https://docs.sentry.io/platforms/javascript/enriching-events/context/#passing-context-directly
 * @param {Error|any} error 
 * @param {{
 *  tags?: { [key:string]: any }
 *  extra?: { [key:string]: any }
 *  contexts?: { [key:string]: any }
 *  user?: { [key:string]: any }
 *  level?: { [key:string]: any }
 *  fingerprint?: { [key:string]: any }
 * }|undefined} context
 */
export function logError(error, context) {
    try {
        if (error instanceof ErrorWithContext) {
            // Use deep merge technich of contexts
            context = mergeOptions({}, error.context, context);
        }

        if (['development', 'develop', 'dev'].includes(ENVIRONMENT || undefined)) {
            console.error({ error, context });
        }

        captureException(error, context);
    } catch (_) {}
}
