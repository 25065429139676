import { loadPrkModal } from '../../async/loadPrkModal.js';
import { logError } from './../logError';

export const showMessage = async (message, title = 'Уведомление') => {
    let messageDialog, dialogElement, messageContainer;

    try {
        const PrkModal = await loadPrkModal(true);
        const createDialog = () => {
            dialogElement = document.createElement('div');
            dialogElement.className = 'prk-modal simple-message-popup'
            dialogElement.setAttribute('tabindex', '-1');
            dialogElement.setAttribute('role', 'dialog');
            dialogElement.innerHTML = `
                <div class="container">
                    <div class="row">
                        <div class="col-12 modal-header">
                            <h4 class="modal-title">${title}</h4>
                        </div>
                        <div class="col-12 modal-body"><p class="js-text-container"></p></div>
                        <div class="col-12 modal-footer">
                            <button type="button" class="btn btn-success" data-prk-modal-action="close">Закрыть</button>
                        </div>
                    </div>
                </div>
            `;
            messageContainer = dialogElement.querySelector('p.js-text-container');
            document.body.appendChild(dialogElement);

            messageDialog = new PrkModal(dialogElement, {
                useHistory: false // Prevent modal from modifying hash
            });
        }

        if (!(messageDialog instanceof PrkModal))
            createDialog();

        messageContainer.innerText = message;
        messageDialog.open();
    }
    
    // When failed loading PrkModal
    catch (error) {
        logError(error);
        alert(message);
    }
};
