import {
    $,
    add,
    addClass,
    animate,
    append,
    appendTo,
    attr,
    blur,
    change,
    children,
    click,
    closest,
    css,
    dataset,
    each,
    empty,
    eq,
    filter,
    find,
    focus,
    hasClass,
    hide,
    html,
    index,
    insertAfter,
    insertBefore,
    is,
    next,
    nextAll,
    off,
    offset,
    on,
    outerHeight,
    outerWidth,
    parent,
    parents,
    prepend,
    prev,
    prevAll,
    prop,
    remove,
    removeAttr,
    removeClass,
    scrollTop,
    show,
    siblings,
    styles,
    submit,
    text,
    toggleClass,
    transform,
    transition,
    transitionEnd,
    trigger,
    val,
} from 'dom7';

// Import custom or modified functions
import after from './functions/after';
import before from './functions/before';
import clone from './functions/clone';
import data from './functions/data';
import fadeIn from './functions/fadeIn';
import fadeOut from './functions/fadeOut';
import fadeTo from './functions/fadeTo';
import height from './functions/height';
import last from './functions/last';
import load from './functions/load';
import ready from './functions/ready';
import toggle from './functions/toggle';
import width from './functions/width';

$.fn = Object.assign($.fn, {
    // Dom7 functions
    add,
    addClass,
    animate,
    append,
    appendTo,
    attr,
    blur,
    change,
    children,
    click,
    closest,
    css,
    dataset,
    each,
    empty,
    eq,
    filter,
    find,
    focus,
    hasClass,
    hide,
    html,
    index,
    insertAfter,
    insertBefore,
    is,
    next,
    nextAll,
    off,
    offset,
    on,
    outerHeight,
    outerWidth,
    parent,
    parents,
    prepend,
    prev,
    prevAll,
    prop,
    remove,
    removeAttr,
    removeClass,
    scrollTop,
    show,
    siblings,
    styles,
    submit,
    text,
    toggleClass,
    transform,
    transition,
    transitionEnd,
    trigger,
    val,

    // Custom or modified functions
    after,
    before,
    clone,
    data,
    fadeIn,
    fadeOut,
    fadeTo,
    height,
    last,
    load,
    ready,
    toggle,
    width,
});

window.$ = window.dom7 = $;
export { $ };
export default $;