/**
 * Generates an array of wendor prefixed styles
 * @todo Think about it
 * @param {string} style
 * @return {string[]}
 */
export function getVendorPrefixedStyle(style) {
    const prefixedStyleNames = [];
    for (const prefix of ['Webkit', 'Moz', 'ms', 'O']) {
        prefixedStyleNames.push(`${prefix}${style.replace(/^\w/, firstLetter => firstLetter.toUpperCase())}`)
    }
    prefixedStyleNames.push(style);
    return prefixedStyleNames;
}
