/**
 * Creates ChildNode[] from html string without using jQuery
 * 
 * !IMPORTANT no script nodes returned by this funciton will be executed
 * without sub routines. HTML5 specifies that a <script> tag inserted with innerHTML should not execute.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#security_considerations
 * 
 * @param {string} html
 * @returns {ChildNode[]}
 */
export const parseHTML = function (html) {
    let tmp = document.createElement( "div" );
    tmp.innerHTML = html;

    const nodes = [...tmp.childNodes];

    // Prevent memory leaks
    tmp.innerHTML = ''; 
    tmp = null;

    return nodes;
}
