export class Product {
    static CURRENCY_FORMAT_OPTIONS = {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0
    };

    constructor ({
        url,
        title,
        previewPictureUrl,
        discountPrice,
        basePrice,
        productId,
        isPickupOnly,
        isOurBrand,
        isPremiumProduct,
    }) {
        this.url = url;
        this.title = title;
        this.previewPictureUrl = previewPictureUrl;
        this.discountPrice = Math.round(discountPrice);
        this.basePrice = Math.round(basePrice);
        this.productId = productId;
        this.isPickupOnly = isPickupOnly;
        this.isOurBrand = isOurBrand;
        this.isPremiumProduct = isPremiumProduct;
    }

    /**
     * Returns discountPrice as formatted currency string
     * @returns {string}
     */
    get formattedPrice () {
        return this.formatCurrency(this.discountPrice);
    }

    /**
     * Returns basePrice as formatted currency string
     * @returns {string}
     */
    get formattedOldPrice () {
        return this.formatCurrency(this.basePrice);
    }

    /**
     * Formats currency with Intl.NumberFormat
     * @param {number} value
     * @returns {string}
     */
    formatCurrency (value) {
        // If Intl.NumberFormat is supported by browser
        if (window.Intl && window.Intl.NumberFormat) {
            const numberFormat = new window.Intl.NumberFormat('ru-RU', Product.CURRENCY_FORMAT_OPTIONS);
            return numberFormat.format(value);
        }

        // Else just add currency symbol
        return `${value.toString()} ₽`;
    }
}

export default Product