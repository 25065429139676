const LOCAL_STORAGE_KEY = 'prk_recent_search_queries';

const DEFAULT_ITEMS_COUNT = 5;

const normalizeQueryText = (text) => {
    return text
        .toLowerCase()
        .trim()
        .replace(/[.,?!'"]/g, '')
        .replace(/\s+/g, ' ')
}

/**
 * @param a {{normalizedText: string; text: string; icon: string; link: string, isPureSearch?: boolean}}
 * @param b {{normalizedText: string; text: string; icon: string; link: string, isPureSearch?: boolean}}
 */
const areQueryItemsEqual = (a, b) => {
    return a.normalizedText === b.normalizedText && a.link === b.link;
}

class RecentSearchQueryRepository {
    /**
     * @type {{normalizedText: string; text: string; icon: string; link: string, isPureSearch?: boolean}[]}
     */
    queries = [];

    constructor() {
        try {
            if (typeof localStorage !== 'undefined') {
                const serializedStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
                this.queries = serializedStorage ? JSON.parse(serializedStorage) : [];
            }
        } catch (e) {
            this.queries = [];
        }
    }

    /**
     * @param item {{text: string; icon: string; link: string; isPureSearch?: boolean, normalizedText?: string}}
     */
    add(item) {
        const normalizedText = normalizeQueryText(item.text);

        item = {
            ...item,
            normalizedText,
        };

        if (normalizedText) {
            this.queries = this.queries.filter((a) => !areQueryItemsEqual(a, item));
            this.queries.unshift(item);
        }

        try {
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.queries));
            }
        } catch (e) {
        }
    }

    /**
     * @param phrase {string}
     * @param quantity {number}
     * @return {{text: string; icon: string; link: string, isPureSearch?: boolean}[]}
     */
    search(phrase, quantity = DEFAULT_ITEMS_COUNT) {
        return this.queries
            .filter((x) => x.normalizedText.indexOf(normalizeQueryText(phrase)) !== -1)
            .slice(0, quantity);
    }

    /**
     * @return {{text: string; icon: string; link: string}[]}
     */
    getRecent(quantity = DEFAULT_ITEMS_COUNT) {
        return this.queries.slice(0, quantity);
    }
}

export default RecentSearchQueryRepository;
