import Swiper, {Navigation} from 'swiper';
import {createPopper} from '@popperjs/core';
import {setMetrikaGoal} from "../common/functions";

export const initPicturesWithPinsSlider = function () {
    const poppers = [];

    const sliders = [...document.querySelectorAll('.pictures-with-pins__pins')];
    for (const slider of sliders) {
        const pins = [...slider.querySelectorAll('.pictures-with-pins__pin')];

        const buttons = [...slider.querySelectorAll('.js-add-to-cart-from-inspiration')];
        for (const button of buttons) {
            button.addEventListener('click', () => {
                setMetrikaGoal('inspiration_block_add_to_cart');
            });
        }

        const links = [...slider.querySelectorAll('.js-pin-link')];
        for (const link of links) {
            link.addEventListener('click', () => {
                setMetrikaGoal('inspiration_block_click');
            });
        }

        for (const pin of pins) {
            const popover = pin.querySelector('.pictures-with-pins__pin-content');

            const popper = createPopper(pin, popover, {
                placement: 'auto',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [8, 8],
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: slider,
                            padding: 8,
                        },
                    },
                    {
                        name: 'flip',
                        enabled: true,
                        options: {
                            fallbackPlacements: ['top', 'right'],
                            boundary: slider,
                        },
                    },
                    {
                        name: 'arrow',
                        options: {
                            padding: ({ placement }) => {
                                const placements = {
                                    'top': 'up',
                                    'bottom': 'down',
                                    'left': 'left',
                                    'right': 'right',
                                };
                                popover.setAttribute('data-popover-pos', placements[placement]);
                                return 10;
                            }
                        },
                    },
                ],
            });

            poppers.push(popper);
        }
    }

    Swiper.use([Navigation]);
    const swiper = new Swiper('.pictures-with-pins', {
        slidesPerView: 1,
        spaceBetween: 16,
        navigation: {
            nextEl: '.pictures-with-pins__button--next',
            prevEl: '.pictures-with-pins__button--prev',
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
            },
        },
        threshold: 5,
    });

    swiper.on('transitionEnd', () => {
        for (const popper of poppers) {
            popper.update();
        }
    });

    const buttons = document.querySelectorAll('.js-add-to-cart-from-inspiration');
    for (const button of buttons) {
        setMetrikaGoal('inspiration_block_add_to_cart');
    }

    const links = document.querySelectorAll('.js-pin-link');
    for (const link of links) {
        setMetrikaGoal('inspiration_block_click');
    }
}