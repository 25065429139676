
import redefine from 'core-js/internals/export';
import getBuiltIn from 'core-js/internals/get-built-in';
import $toString from 'core-js/internals/to-string';
import isObject from 'core-js/internals/is-object';
const nativeFetch = getBuiltIn('fetch'),
      NativeRequest = getBuiltIn('Request'),
      RequestPrototype = NativeRequest && NativeRequest.prototype,
      Headers = getBuiltIn('Headers');

// Wrap `fetch` and `Request` for correct work with `URLQueryParams`
if (typeof Headers === 'function') {
    const wrapRequestOptions = function (init) {
        if (isObject(init)) {
            const body = init.body;
            let headers;
            if (body instanceof URLQueryParams) {
                headers = init.headers ? new Headers(init.headers) : new Headers();
                if (!headers.has('content-type')) {
                    headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
                }
                
                // Older browsers do not call toString method on body,
                // has to call it manually
                return Object.assign({}, init, { body: body.toString(), headers });;
            }
        }
        return init;
    };

    if (typeof nativeFetch === 'function') {
        redefine({ global: true, enumerable: true, forced: true }, {
            fetch: function fetch(input /* , init */) {
                return nativeFetch(input, arguments.length > 1 ? wrapRequestOptions(arguments[1]) : {});
            }
        });
    }

    if (typeof NativeRequest === 'function') {
        var RequestConstructor = function Request(input /* , init */) {
            if (!(this instanceof RequestConstructor)) {
                throw new TypeError('Incorrect Request invocation');
            }
            return new NativeRequest(input, arguments.length > 1 ? wrapRequestOptions(arguments[1]) : {});
        };

        RequestPrototype.constructor = RequestConstructor;
        RequestConstructor.prototype = RequestPrototype;

        redefine({ global: true, forced: true }, {
            Request: RequestConstructor
        });
    }
}

export default class URLQueryParams {
    /**
     * @param {Object|string|FormData} init
     */
    constructor (init) {
        /**
         * @protected
         * @var {Array<{ key: string, value: string }>}
         */
        this._entries = this.extractEntries(init);
    }

    /**
     * @private
     * @param {Object|string|FormData} init
     * @returns {Array<{ key: string, value: string }>}
     */
    extractEntries (init) {
        if (typeof init === 'string')
            return this.fromString(init);

        if (init instanceof FormData)
            return this.fromFormData(init);

        if (typeof init === 'object')
            return this.fromDictionary(init);

        return [];
    }

    /**
     * If there are several entries with the same key
     * values will be concatenated as arrays (comma separated strings)
     * 
     * @returns {string}
     */
    toString () {
        // Using unique keys to prevent dublication of key-value pairs
        const uniqueKeys = [...new Set(this.keys())];
        return uniqueKeys.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.getAll(key))}`).join('&');
    }

    /**
     * @param {string} string
     * @returns {Array<{ key: string, value: string }>}
     */
    fromString (string) {
        const splittedString = string.split('?'),
              queryStringWithHash = splittedString[splittedString.length - 1],
              splittedQueryStringWithHash = queryStringWithHash.split('#'),
              queryString = splittedQueryStringWithHash[0];

        /** @var {Array<{ key: string, value: string }>} */
        let entries = [];

        queryString.split('&').forEach(param => {
            const [key, value] = param.split('=');
            if (key && value)
                entries.push({ key: decodeURIComponent(key), value: decodeURIComponent(value) });
        });

        return entries;
    }

    /**
     * @param {FormData} string
     * @returns {Array<{ key: string, value: string }>}
     */
    fromFormData (formData) {
        /** @var {Array<{ key: string, value: string }>} */
        let entries = [];

        for (const entry of formData.entries) {
            entries.push({ key: entry[0], value: $toString(entry[1]) });
        }

        return entries;
    }

    /**
     * @param {{ [key: strine]: value: any }} string
     * @returns {Array<{ key: string, value: string }>}
     */
    fromDictionary (obj) {
        /** @var {Array<{ key: string, value: string }>} */
        let entries = [];

        for (const key of Object.keys(obj)) {
            entries.push({ key, value: $toString(obj[key]) });
        }

        return entries;
    }

    /**
     * @param {string} key
     * @param {any} value
     */
    append (key, value) {
        this._entries.push({ key: $toString(key), value: $toString(value) });
    }

    /**
     * @param {string} key
     */
    delete (key) {
        let index = 0;
        while (index < this._entries.length) {
            if (this._entries[index].key === key)
                this._entries.splice(index, 1);
            else
                index++;
        }
    }

    /**
     * @param {string} key
     * @returns {string|null}
     */
    get (key) {
        for (const entry of this._entries) {
            if (entry.key === key) {
                return entry.value;
            }
        }
        return null;
    }

    /**
     * @param {string} key
     * @returns {string[]}
     */
    getAll (key) {
        /** @var {string[]} */
        let result = [];
        for (const entry of this._entries) {
            if (entry.key === key) {
                result.push(entry.value);
            }
        }
        return result;
    }

    /**
     * @param {string} key
     * @returns {boolean}
     */
    has (key) {
        for (const entry of this._entries) {
            if (entry.key === key) {
                return true;
            }
        }
        return false;
    }

    /**
     * @param {string} key
     * @param {any} value
     */
    set (key, value) {
        let found = false,
            index = 0;

        while (index < this._entries.length) {
            if (this._entries[index].key === key) {
                if (found) {
                    this._entries.splice(index, 1);
                } else {
                    found = true;
                    this._entries[index].value = $toString(value);
                    index++;
                }
            } else {
                index++;
            }
        }

        if (!found) {
            this.append(key, value);
        }
    }

    sort () {
        this._entries.sort((a, b) => {
            if (a.key === b.key)
                return 0;

            return a.key < b.key ? -1 : 1;
        });
    }

    /**
     * @param {(value: any, index: number, arr: { key: string, value: string }) => void} callback
     */
    forEach (callback) {
        this._entries.forEach(callback);
    }

    /**
     * @returns {string[]}
     */
    keys () {
        return this._entries.map(entry => entry.key);
    }

    /**
     * @returns {string[]}
     */
    values () {
        return this._entries.map(entry => entry.value);
    }

    /**
     * @returns {Array<{ key: string, value: string }>}
     */
    entries () {
        return this._entries.map(entry => entry);
    }

    /**
     * @returns {number}
     */
    get length () {
        return this._entries.length;
    }
}
