import rAF from '../utils/rAF';
import cAF from '../utils/cAF';

/**
 * Class provides methods to scroll the viewport (window or any HTMLElement)
 * with animation and support of cancellation.
 * Also provides methods to get viewport's height, width, scrollTop, scrollLeft
 * and methods to get an element's position and visibility inside of viewport.
 */
export default class Viewport {

  /**
   * @param {HTMLElement|undefined} container
   * @param {{ maxScrollDuration: number }|undefined} options
   */
  constructor (container, options) {
    /** protected @var {HTMLElement} */
    this._container = container instanceof HTMLElement
      ? container
      : document.documentElement || (document.body && document.body.parentNode) || document.body; // Compatibility with older browsers

    /** protected @var {{ maxScrollDuration: number = 1000 }} */
    this._options = {
      maxScrollDuration: options && typeof options.maxScrollDuration === 'number'
        ? options.maxScrollDuration
        : 1000
    };

    /** protected @var {number|null} */
    this._frameHandle = null;

    /** protected @var {Function|null} */
    this._cancelScroll = null;
  }

  /**
   * Returns scrollTop of Viewport.container
   * @returns {number}
   */
  get scrollTop () {
    return this._container.scrollTop
  }

  /**
   * Sets scrollTop of Viewport.container.
   * This is an alias for Viewport::scroll method
   *
   * !IMPORTANT if you need a promise use Viewport::scroll method directly
   *
   * @param {number} value
   */
  set scrollTop (value) {
    this.scroll(value);
  }

  /**
   * Returns scrollLeft of Viewport.container
   * @returns {number}
   */
  get scrollLeft () {
    return this._container.scrollLeft;
  }

  /**
   * Sets scrollLeft of Viewport.container
   * This is an alias for Viewport::scroll method
   *
   * !IMPORTANT if you need a promise use Viewport::scroll method directly
   *
   * @param {number} value
   */
  set scrollLeft (value) {
    this.scroll(null, value);
  }

  /**
   * Returns width of Viewport.container
   * @returns {number}
   */
  get width () {
    return this._container.clientWidth;
  }

  /**
   * Returns height of Viewport.container
   * @returns {number}
   */
  get height () {
    return this._container.clientHeight;
  }

  /**
   * Returns viewport's container element
   * @returns {HTMLElement}
   */
  getContainer () {
    return this._container;
  }

  /**
   * Cancels scrolling by rejecting the scroll promise
   */
  cancelScroll() {
    if (this._cancelScroll instanceof Function) {
      cAF.call(window, this._frameHandle);
      this._cancelScroll("Scroll was cancelled");
      this._cancelScroll = null;
    }
  }

  /**
   * Returns TRUE if element is child of Viewport.container
   * @param {HTMLElement} element
   * @returns {boolean}
   */
  contains (element) {
    if (element instanceof HTMLElement) return this._container.contains(element);
    return false;
  }

  /**
   * Returns width, height, bottom, left, right and top of element
   * @param {HTMLElement} element
   * @returns {{
   *  width: number,
   *  height: number
   *  bottom: number,
   *  left: number,
   *  right: number,
   *  top: number
   * }}
   */
  getElementPosition (element) {
    let width  = 0,
        height = 0,
        bottom = 0,
        left   = 0,
        right  = 0,
        top    = 0;

    if (this.contains(element)) {
      const elementRect = element.getBoundingClientRect();
      const baseDomElements = [document.documentElement, document.body && document.body.parentNode, document.body].filter(el => el);
      const viewportRect = baseDomElements.indexOf(this._container) > -1
        ? {
            // For document.documentElement or document.body can't directly use getBoundingClientRect,
            // because we want the boundings of the window
            width: this.width,
            height: this.height,
            bottom: this.height,
            left: 0,
            right: this.width,
            top: 0
          }
        : this._container.getBoundingClientRect();

      const viewportLeft = this.scrollLeft - viewportRect.left,
            viewportTop = this.scrollTop - viewportRect.top;

      width  = elementRect.width;
      height = elementRect.height;
      bottom = viewportTop + elementRect.bottom;
      left   = viewportLeft + elementRect.left;
      right  = viewportLeft + elementRect.right;
      top    = viewportTop + elementRect.top;
    }

    return { width, height, bottom, left, right, top };
  }

  /**
   * Returns current viewport sizes
   * @returns {{
   *  width: number,
   *  height: number
   *  bottom: number,
   *  left: number,
   *  right: number,
   *  top: number
   * }}
   */
  getViewport () {
    const width  = this.width,
          height = this.height,
          top    = this.scrollTop,
          left   = this.scrollLeft,
          bottom = top + height,
          right  = left + width;

    return { width, height, bottom, left, right, top };
  }

  /**
   * If onlyFullyVisible is FALSE function returns TRUE if element is at lease partly visible inside of viewport.
   * If onlyFullyVisible is TURE function returns TRUE if element is fully visible inside of viewport
   * @param {HTMLElement} element
   * @param {boolean} onlyFullyVisible
   * @returns {boolean}
   */
  isVisible(element, onlyFullyVisible) {
    if (!this.contains(element)) return false;

    const e = this.getElementPosition(element);
    const v = this.getViewport();

    // If element or any of its parents has display: none
    if (
      e.width === 0 &&
      e.height === 0 &&
      e.left === e.right &&
      e.left === v.left &&
      e.top === e.bottom &&
      e.top === v.top
    )
      return false;

    // Return true if element is fully visible
    if (true === onlyFullyVisible) {
      return !!(( // '!!' ensures the boolean is returned
          (
            // If element is placed within viewport's top and bottom
            (e.top >= v.top && e.top < v.bottom) &&
            (e.bottom <= v.bottom && e.bottom > v.top)
          ) || (
            // If element is bigger than viewport but starts at viewport's top
            (e.top === v.top) &&
            (e.bottom > v.bottom && e.bottom > v.top)
          )
        ) && (
            // If element is placed within viewport's left and right
            (e.left >= v.left && e.left < v.right) &&
            (e.right <= v.right && e.right > v.left)
          ) || (
            // If element is bigger than viewport but starts at viewport's left
            (e.left === v.left) &&
            (e.right > v.right && e.right > v.left)
      ));
    }

    // Return true if at least a part of the element is visible
    return !!(( // '!!' ensures the boolean is returned
        (e.top >= v.top && e.top < v.bottom) ||
        (e.bottom <= v.bottom && e.bottom > v.top) ||
        (e.top < v.top && e.bottom > v.bottom)
      ) && ( (e.left >= v.left && e.left < v.right) ||
        (e.right <= v.right && e.right > v.left) ||
        (e.left < v.left && e.right > v.right)
    ))
  }

  /**
   * Scrolls the viewport to targetTop and targetLeft
   * @param {number|null|undefined} targetTop
   * @param {number|null|undefined} targetLeft
   * @returns {Promise<string|undefined>}
   */
  scroll (targetTop, targetLeft) {
    this.cancelScroll(); // Cancel already running scrolling

    const maxScrollTopPosition = this._container.scrollHeight - this.height;
    const maxScrollLeftPosition = this._container.scrollWidth - this.width;

    // Prevent ensure that targetTop and targetLeft are within viewport size
    targetTop  = Math.max(0, Math.min(
      // targetTop ?? this.scrollTop syntax is not supported by angular
      targetTop === 0
        ? 0
        : targetTop || this.scrollTop,
      maxScrollTopPosition
    ));
    targetLeft = Math.max(0, Math.min(
      // targetLeft ?? this.scrollLeft syntax is not supported by angular
      targetLeft === 0
        ? 0
        : targetLeft || this.scrollLeft,
      maxScrollLeftPosition
    ));

    const initialScrollTop   = this.scrollTop,
          initialScrollLeft  = this.scrollLeft,
          animationStartTime = Date.now(),
          animationDuration  = Math.min(
            Math.max(
              Math.abs(targetTop - initialScrollTop),
              Math.abs(targetLeft - initialScrollLeft)
            ),
            this._options.maxScrollDuration
          );

    const promise = new Promise((resolve, reject) => {
      this._cancelScroll = reject;

      /**
       * Animaties the scrolling.
       */
      const animateScroll = () => {
        let t = Math.min((Date.now() - animationStartTime) / animationDuration, 1);
        t = t < 0.5
          ? 4*t*t*t
          : (t-1)*(2*t-2)*(2*t-2)+1;

        this._container.scrollLeft = (targetLeft - initialScrollLeft) * t + initialScrollLeft;
        this._container.scrollTop  = (targetTop - initialScrollTop) * t + initialScrollTop;

        if (t < 1) {
            this._frameHandle = rAF.call(window, animateScroll);
        } else {
            resolve();
        }
      }

      this._frameHandle = rAF.call(window, animateScroll);
    });

    return promise
      .catch(() => Promise.resolve());
  }

  /**
   * Scrolls to an Element.
   * @param {HTMLElement} element
   * @param {number|null|undefined} offsetTop
   * @param {number|null|undefined} offsetLeft
   * @returns {Promise<string|undefined>}
   */
  scrollToElement (element, offsetTop, offsetLeft) {
    if (!this.contains(element))
      return Promise.reject(new Error("Couldn't scroll to element because the Viewport doesn't contain it."));

    const v = this.getViewport(),
          e = this.getElementPosition(element),
          targetTop = e.top - (offsetTop || 0),
          targetLeft = e.left < v.left || e.right > v.right
            ? e.left - (offsetLeft || 0)
            : null;

    return this.scroll(targetTop, targetLeft);
  }

  /**
   * Scrolls element into viewport. Not nesessarily to the viewport top.
   * @param {HTMLElement} element
   * @param {number|null|undefined} verticalOffset
   * @param {number|null|undefined} horisontalOffset
   * @returns {Promise<string|undefined>}
   */
  scrollIntoView(element, verticalOffset, horisontalOffset) {
    if (!this.contains(element))
      return Promise.reject(new Error("Couldn't scroll to element because the Viewport doesn't contain it."));

    // No need to scroll if element is visible
    if (this.isVisible(element, true))
      return Promise.resolve();

    const v = this.getViewport(),
          e = this.getElementPosition(element),
          targetTop = e.top < v.top
            ? e.top - (verticalOffset || 0) // to element's top
            : e.bottom - v.height + (verticalOffset || 0), // to element's bottom
          targetLeft = e.left < v.left || e.right > v.right
            ? e.left - (horisontalOffset || 0)
            : null;

    return this.scroll(targetTop, targetLeft);
  }
}
