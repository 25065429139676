import getBuiltIn from 'core-js/internals/get-built-in';
import redefine from 'core-js/internals/export';
import isObject from 'core-js/internals/is-object';
import { ErrorWithContext } from './error-with-context';

const nativeFetch = getBuiltIn('fetch');

// Redefine fetch to use credentials: 'same-origin' by default (which is, but older browsers used credentials: 'omit')
// Make every fetch to automatically throw if !response.ok (when response.code > 299), unlike native fetch that only throws on network errors
if (nativeFetch instanceof Function) {

    /**
     * @param {Response} response
     * @returns {Promise<Response>}
     * @throws {Error}
     */
    const checkFetchResponse = async function (response) {
        if (response.ok) return Promise.resolve(response);

        const { url, status, statusText, headers } = response;
        let message = `Error code ${status}`;
        if (statusText)
            message += `: ${statusText}`;

        const contentType = headers.get('content-type') ?? 'text/plain';
        const contentLength = Number(headers.get('content-length') ?? 0);

        let jsonParseError = null;
        let jsonData = null;
        let originalMessage;
        
        // When error resonse in json format, try to extract additional data
        if (['application/json', 'application/problem+json'].includes(contentType) && contentLength > 0) {
            try {
                jsonData = await response.json();
                originalMessage = jsonData.error?.message;
                if (originalMessage)
                    message += `: ${originalMessage}`;
            } catch (jsonError) {
                jsonParseError = jsonError;
            }
        }

        message += `. Url: ${url}`;
        const error = new ErrorWithContext(message, {
            extra: { jsonParseError, originalMessage, jsonData }
        });
        throw error;
    }

    const wrapRequestOptions = function (init) {
        if (isObject(init)) {
            if (typeof init.credentials === 'undefined') {
                return Object.assign({}, init, { credentials: 'same-origin' });
            }
        }
        return init;
    };

    redefine({ global: true, enumerable: true, forced: true }, {
        fetch: function fetch(input /* , init */) {
            return nativeFetch(input, arguments.length > 1 ? wrapRequestOptions(arguments[1]) : {})
                .then(checkFetchResponse);
        }
    });
}