import { hideLoader, showLoader } from '../globalFunctions';
import { logError } from '../utils/logError';

export function initUserOnboarding() {
    $('.js-new-user-timer').fadeIn(2000);
    onNextTimerTick();

    $(document).on('click', '.js-new-user-timer .close', function () {
        $('.js-new-user-timer').remove();
    });
}

function onNextTimerTick() {
    let $timerContainer = $('.js-new-user-timer');
    if ($timerContainer.length) {
        let timeRemaining = parseInt($timerContainer.data('timer'));

        if (timeRemaining > 0) {
            $timerContainer.data('timer', timeRemaining - 1);
            $timerContainer.find('.counter').html(timeRemaining - 1);
            setTimeout(onNextTimerTick, 1000);
        } else {
            $timerContainer.find('.gift-wait').hide();
            $timerContainer.find('.gift-take').fadeIn(3000);

            const isDetailPage = document.querySelectorAll('.detail-product-body').length !== 0;

            // Get gift from backend
            showLoader($timerContainer);
            fetch(`/include/onboarding/gift/?is_detail=${isDetailPage}`, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
            })
                .then(response => response.text())
                .then(htmlTemplate => {
                    $timerContainer.after(htmlTemplate);
                    hideLoader($timerContainer);
                })
                .catch(reason => {
                    logError(reason);
                    hideLoader($timerContainer);
                });
        }
    }
}