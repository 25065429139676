import { handleModuleLoadingError } from './handleModuleLoadingError.js';

/**
 * @param {boolean} customErrorHandling pass TRUE to use custom error handling
 * @return {Promise<Paymaster>}
 */
export const loadPaymaster = function (customErrorHandling) {
    const promise = import('/js/payments/paymaster').then(m => m.default);

    if (!customErrorHandling)
        promise.catch(handleModuleLoadingError);

    return promise;
};
