const defaultDisplayMap = {};

/**
 * @prop {HTMLElement} element
 * @returns {string}
 */
export const getDefaultDisplay = function (element) {
	let tempElement,
		  doc = element.ownerDocument,
		  nodeName = element.nodeName,
		  display = defaultDisplayMap[nodeName];

	if (display) {
		return display;
	}

	tempElement = doc.body.appendChild(doc.createElement(nodeName));
	display = getComputedStyle(tempElement)['display'];

	tempElement.parentNode.removeChild(tempElement);

	if ( display === "none" ) {
		display = "block";
	}
	defaultDisplayMap[nodeName] = display;

	return display;
}

export default getDefaultDisplay;