/**
 * Returns last element of collection
 * @this {Dom7}
 * @returns {Dom7}
 */
export const last = function () {
    if (this.length) {
      return $(this[this.length - 1]);
    }

    return this;
};

export default last;
