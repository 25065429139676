import Swiper, {Pagination} from 'swiper';
import { throttle } from '../decorators';
import Viewport from '../common/viewport';
import { logError } from '../utils/logError';

export class ProductImageSlider {
    static _observedUninitializedSliders = [];
    static _viewport = new Viewport(window);

    constructor() {
        Swiper.use([Pagination]);

        this.scanUninitializedSliders = this.scanUninitializedSliders.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.throttledHandleScroll = throttle(this.handleScroll, 100);
        this.createSlider = this.createSlider.bind(this);

        this.scanUninitializedSliders();
        this.handleScroll();

        document.addEventListener('catalogupdate', () => {
            this.scanUninitializedSliders();
            this.handleScroll();
        });
    }

    scanUninitializedSliders() {
        document.removeEventListener('scroll', this.throttledHandleScroll);
        document.removeEventListener('resize', this.throttledHandleScroll);

        const selector = '.js-product-image-slider:not(.swiper-container-initialized):not(.js-product-image-slider-observed)',
            uninitializedSliders = [...document.querySelectorAll(selector)];

        for (const slider of uninitializedSliders) {
            slider.classList.add('js-product-image-slider-observed');
            ProductImageSlider._observedUninitializedSliders.push(slider);
        }

        if (ProductImageSlider._observedUninitializedSliders.length > 0) {
            document.addEventListener('scroll', this.throttledHandleScroll);
            document.addEventListener('resize', this.throttledHandleScroll);
        }
    }

    handleScroll() {
        let length = ProductImageSlider._observedUninitializedSliders.length;
        for (let i = 0; i < length; i++) {
            const slider = ProductImageSlider._observedUninitializedSliders[i];
            if (ProductImageSlider._viewport.isVisible(slider)) {
                ProductImageSlider._observedUninitializedSliders.splice(i--, 1);
                this.createSlider(slider);
            }
        }

        if (ProductImageSlider._observedUninitializedSliders.length === 0) {
            document.removeEventListener('scroll', this.throttledHandleScroll);
            document.removeEventListener('resize', this.throttledHandleScroll);
        }
    }

    createSlider(slider) {
        const initialImage = slider.querySelector('img');

        const jsonGallery = initialImage.dataset.gallery;
        if (!jsonGallery) {
            return;
        }

        let urls = [];
        try {
            urls = JSON.parse(jsonGallery);
        } catch (err) {
            console.error(err);
            logError(err); // log error to Sentry
            return;
        }

        if (urls.length < 2) {
            return;
        }

        if (initialImage.classList.contains('lazyloading')) {
            initialImage.classList.remove('lazyloading');
        }

        const {src, alt} = initialImage;

        const width = initialImage.getAttribute('width'),
            height = initialImage.getAttribute('height');

        let slides = '';
        urls.forEach(url => {
            slides += `
                <div class="swiper-slide">
                    <img
                        src="${src}"
                        data-src="${url}"
                        class="lazyload"
                        alt="${alt}"
                        width="${width}"
                        height="${height}"
                    >
                </div>
            `;
        });

        slider.innerHTML = `
            <div class="swiper-wrapper">
                ${slides}
            </div>
            <div class="swiper-pagination"></div>
        `;

        new Swiper(slider, {
            pagination: {
                el: '.swiper-pagination'
            }
        });
    }
}