import { handleModuleLoadingError } from './handleModuleLoadingError.js';

/**
 * @param {boolean} customErrorHandling pass TRUE to use custom error handling
 * @return {Promise<PrkCollapsible>}
 */
export const loadPrkCollapsible = (customErrorHandling) => {
    const promise = import('/js/prk/prk-collapsible').then(m => m.default);

    if (!customErrorHandling)
        promise.catch(handleModuleLoadingError);

    return promise;
};
