import { handleModuleLoadingError } from './handleModuleLoadingError.js';
import { loadJQuery } from './loadJQuery.js';

/**
 * @param {boolean} customErrorHandling pass TRUE to use custom error handling
 * @return {Promise<unknown>}
 */
export const loadAutocomplete = function (customErrorHandling) {
    // Can't load in parallel
    const promise = loadJQuery(true)
            .then(() => import('/js/vendors/jquery.easy-autocomplete-customized.js?20230628'));
    
    if (!customErrorHandling)
        promise.catch(handleModuleLoadingError);

    return promise;
};
