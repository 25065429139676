/**
 * @this {Dom7}
 * @param {string|HTMLElement|Dom7}
 * @returns {Dom7}
 */
export const before = function (content) {
    this.forEach(element => {
        const $wrap = $(document.createElement('div'));
        $wrap.html(content);
        $wrap.insertBefore(element);
    });

    return this;
}

export default before;