/**
 * Toggle visibility
 * @this {Dom7}
 * @returns {Dom7}
 */
export const toggle = function () {
    this.forEach(element => {
        if (getComputedStyle(element)['display'] === 'none')
            $(element).show();
        else
            $(element).hide();
    });
};

export default toggle;