import getDefaultDisplay from '../util/getDefaultDisplay';

/**
 * @prop {number|'fast'|'slow'} duration in milliseconds. 'fast' is 200ms, 'slow' is 600ms.
 * @prop {number} opacity between 0 and 1
 * @prop {string|Function|undefined} optionalParam1 string will be interpreted as easing method (for jQuery compatibility) and ignored. Function will be interpreted as a onComplete callback.
 * @prop {Function|undefined} optionalParam2 is only valid when optionalParam1 is a string. In that case is interpreted as a onComplete callback.
 * @returns {{ duration: number, opacity: number, onComplete: Function }}
 */
const initFadeFunctionArguments = function (duration, opacity, ...rest) {
    let onComplete = function () {};

    if (typeof duration === 'string') {
        switch (duration) {
            case 'fast':
                duration = 200;
                break;

            case 'slow':
                duration = 600;
                break;
            
            default:
                duration = 400;
                break;
        }
    }

    if (typeof duration !== 'number' || isNaN(duration) || !isFinite(duration))
        duration = 400;

    opacity = Math.max(0, Math.min(opacity, 1));

    if (rest.length) {
        if (rest[0] instanceof Function) {
            onComplete = rest[0];
        } else if (typeof rest[0] === 'string' && rest[1] instanceof Function) {
            // Ignore easing parameter of jQuery, use default easing
            onComplete = rest[1];
        }
    }

    return { duration, opacity, onComplete };
}

/**
 * @param {Dom7}
 */
const showHiddenElements = function ($elements) {
    $elements.forEach(element => {
        if (getComputedStyle(element).display === 'none') {
            $(element).css({
                display: getDefaultDisplay(element),
                opacity: 0,
            });
        }
    });
}

/**
 * @param {Dom7}
 */
const hideElements = function ($elements) {
    $elements.css({ display: 'none' });
}

/**
 * @this {Dom7}
 * @param {number|'fast'|'slow'} duration in milliseconds. 'fast' is 200ms, 'slow' is 600ms.
 * @param {number} opacity between 0 and 1
 * @param {string|Function|undefined} optionalParam1 string will be interpreted as easing method (for jQuery compatibility) and ignored. Function will be interpreted as a onComplete callback.
 * @param {Function|undefined} optionalParam2 is only valid when optionalParam1 is a string. In that case is interpreted as a onComplete callback.
 * @returns {Dom7}
 */
export const fadeTo = function () {
    const { duration, opacity, onComplete } = initFadeFunctionArguments(...arguments);

    if (opacity > 0)
        showHiddenElements(this);

    if (duration === 0) {
        this.css({ opacity });
        if (opacity === 0)
            hideElements(this);
        onComplete.call(this);
        return this;
    } else {
        return this.animate({ opacity }, {
            duration,
            complete: () => {
                if (opacity === 0)
                    hideElements(this);
                onComplete.call(this);
            }
        });
    }
}

export default fadeTo;