import fadeTo from './fadeTo';

/**
 * Shortcut for fadeTo
 * @this {Dom7}
 * @param {number|'fast'|'slow'} duration in milliseconds. 'fast' is 200ms, 'slow' is 600ms.
 * @param {Function|undefined} onComplete
 * @returns {Dom7}
 */
export const fadeIn = function (duration, onComplete) {
    return fadeTo.call(this, duration || 400, 1, onComplete || function () {});
}

export default fadeIn;