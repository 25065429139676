import {sendUserInfo2RetailRocket, setMetrikaGoal} from '../common/functions';
import Viewport from '../common/viewport';
import { hideLoader, showLoader } from '../globalFunctions';
import { showMessage } from '../utils/showMessage';
import { logError } from '../utils/logError';
import { loadAjaxForm } from '../async/loadAjaxForm.js';
import { loadOwlCarousel } from '../async/loadOwlCarousel.js';
import URLQueryParams from '/js/url-query-params';
import { Countdown } from '../prk/countdown';

export function initButtons() {
  const viewport = new Viewport(); // Use window as a scroll container

  /**
   * @param {{
   *    ajax: string,
   *    col: number,
   *    is_new_block?: string,
   *    page: number,
   *    prop?: string,
   *    section_id?: string,
   *    type: string
   *  }} params
   * @returns {JQueryPromise}
   */
  const fetchMore = params => fetch('/include/p-load-more.php', {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: new URLQueryParams(params),
    });

  /**
   * Handles click on 'show-more' buttons
   * @param {MouseEvent|TouchEvent|PointerEvent} event
   */
  const showMoreButtonClickHandler = async function (event) {
    // Initialize
    const showMoreButton         = this,
          nextPage               = parseInt(showMoreButton.dataset.attr_nextPage),
          lastPage               = parseInt(showMoreButton.dataset.attr_totalPages),
          itemsPerPage           = parseInt(showMoreButton.dataset.attr_itemsPerPage) || 4,
          yaMetrikaGoal          = showMoreButton.dataset.yaMetrikaGoal,
          $catalogProductsIndex  = $(showMoreButton).parents('.catalog-products-index'),
          propertyName           = $catalogProductsIndex[0].dataset.attr_prop,
          sectionId              = $catalogProductsIndex[0].dataset.attr_sid;

    // Construct request parameters
    const params = {
      ajax: 'y',
      col: itemsPerPage,
      page: nextPage
    };

    if (sectionId) {
      params.type       = 'section_id';
      params.section_id = sectionId;
    } else {
      params.type = 'by_property';
      params.prop = propertyName;
    }

    // Fetch and render
    try {
      showLoader($catalogProductsIndex);
      const response = await fetchMore(params);
      const { html } = await response.json();

      // render
      $catalogProductsIndex.find('.products-container').append(html);

      // Update nextPage attribute
      if (nextPage && lastPage && showMoreButton instanceof HTMLElement) {
        const newNextPage = nextPage + 1;
        showMoreButton.dataset.attr_nextPage = newNextPage;
        if (newNextPage > lastPage)
          showMoreButton.style.display = 'none';
      }

      updateCustomerView();

      // If not empty
      if (yaMetrikaGoal)
        setMetrikaGoal(yaMetrikaGoal);

      // Scroll
      const catalogProductsIndexBottom = viewport.getElementPosition($catalogProductsIndex[0]).bottom,
            viewportBottomOffset = 30, // Add 30px offset from viewport bottom
            bottomInfoLineHeight = viewport.getElementPosition(document.querySelector('.bottom-info-line')).height;
      viewport.scrollTop = catalogProductsIndexBottom - viewport.height + viewportBottomOffset + bottomInfoLineHeight;

      document.dispatchEvent(new Event('catalogupdate'));
    }
    catch (error) {
      console.error(error);
      logError(error);

      // On request error hide 'show-more' button as if nothing to load
      showMoreButton.style.display = 'none';
    }
    finally {
      hideLoader($catalogProductsIndex);
    }
  };

  /**
   * Loads tab content if needed.
   * @param {MouseEvent|TouchEvent|PointerEvent} event
   */
  const tabClickHandler = async function (event) {
    if (this.classList.contains('loaded')) return;
    
    // Initialize
    const tab           = this,
          propertyName  = tab.dataset.attr_prop,
          sectionId     = tab.dataset.attr_sid,
          yaMetrikaGoal = tab.dataset.yaMetrikaGoal,
          $ct_box       = $(tab).parents('.tabs').find('div.tabs__content').eq($(this).index()),
          $catalogProductsIndex = $ct_box.find('.catalog-products-index');

    // Construct request parameters
    const params = {
      ajax: 'y',
      col: 4,
      is_new_block: 'y'
    };

    if (sectionId) {
      params.type       = 'section_id';
      params.section_id = sectionId;
    } else {
      params.type = 'by_property';
      params.prop = propertyName;
    }

    // Fetch and render
    try {
      showLoader($catalogProductsIndex);
      const response = await fetchMore(params);
      const { html } = await response.json();
      $catalogProductsIndex.append(html);
      tab.classList.add('loaded');
      updateCustomerView();
      if (yaMetrikaGoal)
        setMetrikaGoal(yaMetrikaGoal);

      const bottomInfoLineHeight = viewport.getElementPosition(document.querySelector('.bottom-info-line')).height;
      viewport.scrollIntoView($catalogProductsIndex[0], 30 + bottomInfoLineHeight);

      document.dispatchEvent(new Event('catalogupdate'));
    }
    catch (error) {
      console.error(error);
      logError(error);
    }
    finally {
      hideLoader($ct_box.find('.catalog-products-index'));
    }
  }

  $(document).on('click', '.js-product-sales .js-show-more', showMoreButtonClickHandler);
  $(document).on('click', '.js-product-sales-custom .js-show-more', showMoreButtonClickHandler);

  $('.tabs-buttons__item').on('click', tabClickHandler);
}

export function initTodayOnlyCountdown() {
  const localDate = new Date(),
        utcTimeInMilliseconds = localDate.getTime() + localDate.getTimezoneOffset() * 60 * 1000,
        moscowTimeOffset = 3 * 60 * 60 * 1000, // 3 hours in milliseconds
        moscowTimeInMilliseconds = utcTimeInMilliseconds + moscowTimeOffset,
        moscowDate = new Date(moscowTimeInMilliseconds),
        millisecondsPassedTodayInMoscow = ((moscowDate.getHours() * 60 + moscowDate.getMinutes()) * 60 + moscowDate.getSeconds()) * 1000,
        moscowTomorrowDate = new Date(moscowDate.getFullYear(), moscowDate.getMonth(), moscowDate.getDate() + 1),
        moscowTimeLeftBeforeTomorrowDate = new Date(moscowTomorrowDate.getTime() - millisecondsPassedTodayInMoscow);

  for (const countdownElement of [...document.querySelectorAll('.js-countdown')]) {
    new Countdown(countdownElement, moscowTimeLeftBeforeTomorrowDate);
  }
}

export async function initSubscribeEmailForm() {
  $(document).on('click', '.subscribe form button[type=submit]', function() {
    showLoader($(this).parents('.input-group'));
  });
  await loadAjaxForm();
  jQuery('.subscribe form').ajaxForm({
    success:function(data){
      hideLoader($('.subscribe').find('.input-group'));
      showMessage(data.success['text']);
    },
    error:function(data){
      hideLoader($('.subscribe').find('.input-group'));
    }
  });
}

export function initSubscribeEmailHandler() {
  $(document).on('submit', '.js-subscribeEmailForm', () => {
    try {
      sendUserInfo2RetailRocket($('#subscribeEmail').val(), {});
    } catch(e) {}
  });
}

export async function initIndexSlider() {
  await loadOwlCarousel();
  jQuery('.main-carousel .owl-carousel').owlCarousel({
    autoplay: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 300,
    autoplayHoverPause: true,
    items: 1,
    dots: true,
    dotsEach: true,
    rewind: true
  });

  // Ya.Metrika counter events
  $(document).on('click', '.big-sale .col-xs-6', function() {
    setMetrikaGoal('MSPOP' + $(this).index());
  });
  $(document).on('click', '#wrapper.index .footer a', function() {
    setMetrikaGoal('MSFOOTER');
  });
}

