import { getQueryParam } from '../utils/getQueryParam';
import { logError } from '../utils/logError';
import { ready } from '../utils/ready';
import Viewport from './viewport';
import PrkProductSlider from './../prk/prk-slider/prk-product-slider/prk-product-slider.js';
import {handleClickOnPrkCollapsible} from '../prk/prk-collapsible/handlers';
import {hideLoader, showLoader} from "../globalFunctions";
import {debounce,throttle} from "../decorators";
import PrkProductSliderDataProvider from "../data-providers/prk-product-slider-data-provider";
import { loadAutocomplete } from './../async/loadAutocomplete.js';
import { loadIMaskPhone } from './../async/loadIMaskPhone.js';
import { loadPaymaster } from './../async/loadPaymaster.js';
import URLQueryParams from '/js/url-query-params';
import $ from "../dom7";
import RecentSearchQueryRepository from './RecentSearchQueryRepository';
import { ErrorWithContext } from "../error-with-context.js";

export function setMetrikaGoal(name) {
    // Check Ya.Metrika presence and reach goal
    waitForAndDo(function () {
        return window['ym'];
    }, function () {
        try {
            window['ym'](yaMetrikaCounterID, 'reachGoal', name);
        } catch (e) {}
    });
}

export function pushDataLayerYandex(data) {
    // Check Ya.Metrica dataLayer presence and push data
    waitForAndDo(function () {
        return window['dataLayerYandex'];
    }, function () {
        window['dataLayerYandex'].push(data);
    });
}

export function triggerAddToCartExternalEvents(prodId, name, price, quantity, isRecommendation = false) {
    // Register view and add_to_cart events
    setMetrikaGoal('MSKORZINA');

    // Yandex E-Commerce datalayer
    pushDataLayerYandex({
        "ecommerce": {
            "add": {
                "products": [
                    {
                        "id": prodId,
                        "name": name,
                        "price": price,
                        "quantity": quantity
                    }
                ]
            }
        }
    });

    // RetailRocket Add to cart tracker
    //
    // !IMPORTANT do not send tracking event when product is added from
    // RR recommendation – in that case PrkProductSlider sends event itself
    // with additional parameters
    if (!isRecommendation) {
        (new PrkProductSliderDataProvider()).fetch(
            { event: PrkProductSliderDataProvider.TRACKING_EVENTS.ADD_TO_BASKET },
            { productId: typeof prodId === 'number' ? prodId : parseInt(prodId) }
        );
    }
}

export function triggerDownloadAppExternalEvents() {
    setMetrikaGoal('download_app');
}

export function showValidationError(input, errorText) {
    input.closest('div').addClass('validation-active');

    if (input.siblings('.validation-block').length) {
        input.siblings('.validation-block').show().find('.validation-message').text(errorText);
    } else {
        input.after(
            '<div class="validation-block" style="display: block;"><div class="validation-message">' + errorText + '</div></div>'
        );
    }
}

/**
 * Shows validation errors at inputs of a form respectively
 * @param {HTMLFormElement}
 * @param {Object} validationErrors
 * @return {void}
 */
export function showValidationErrors(form, validationErrors) {
    for (const inputName in validationErrors) {
        const input = form.querySelector(`[name="${inputName}"]`);
        if (null === input) continue;

        const errorList = document.createElement('ul');
        errorList.classList.add('validation-block');

        for (const error of validationErrors[inputName]) {
            errorList.innerHTML += `<li>${error}</li>`;
        }

        $(input).after(errorList)
            .parent('.input-wrap').addClass('validation-active');
    }
}
/**
 * Clears validation errors at inputs of a form respectively
 * @param {HTMLFormElement}
 * @return {void}
 */
export function clearValidationErrors(form) {
    for (const errors of [...form.querySelectorAll('.validation-block')]) {
        $(errors).parent('.validation-active').removeClass('validation-active');
        errors.remove();
    }
}

export function hideValidationError(input) {
    if (input.siblings('.validation-block').length) {
        input.siblings('.validation-block').remove();
        input.closest('.validation-active').removeClass('validation-active');
    }
}

// Shows frontend error messages for a while and returns validation result
export function checkIfFormHasValidationErrors(form) {
    let inputs = $(form).find('input').filter(input => !input.classList.contains('hidden-input') && !$(input).parents('.tab-pane:not(.active)').length);
    let hasValidationErrors = false;

    inputs.each(input => {
        if (!input.checkValidity()) {
            hasValidationErrors = true;
            let validationMessage = '';

            if (input.validity.valueMissing) {
                if (input.type === 'checkbox')
                    validationMessage = 'Чтобы продолжить, примите условия';
                else
                    validationMessage = 'Заполните это поле';
            } else if (input.validity.typeMismatch) {
                if (input.type === 'email')
                    validationMessage = 'Введен неправильный формат почты';
            }

            showValidationError($(input), validationMessage);
            setTimeout(() => { hideValidationError($(input)); }, 2000);
        }
    });

    return hasValidationErrors;
}

export function isMobile() {
    return window.isMobileBackend;
}

export function copyTextToClipboard(element) {
    /* The .text() method cannot be used on form inputs or scripts.
    To set or get the text value of input or textarea elements, use the .val() method */
    let valueToSave = $(element).text() ? $(element).text() : $(element).val();
    let $temp = $("<input>");
    $("body").append($temp);
    $temp.val(valueToSave)[0].select();
    document.execCommand("copy");
    $temp.remove();
}

/**
 * @param {string} email
 * @param {{[key: string]: any}} info
 */
export function sendUserInfo2RetailRocket(email, info) {
    (new PrkProductSliderDataProvider()).fetch(
        { event: PrkProductSliderDataProvider.TRACKING_EVENTS.SET_CONTACT },
        { email, customData: info }
    );
}

/**
 * Hides menu items from .prk-top-menu
 * that do not fit into container (prevent overflowing)
 */
export const initPrkTopMenu = function () {
    const menu = document.querySelector('.prk-top-menu');
    if (!menu) return;

    const menuViewport = new Viewport(menu);
    const menuItems = [...menu.querySelectorAll('.dropdown.lvl-1')];
    for (const item of menuItems) {
        if (!menuViewport.isVisible(item, true))
            item.style.display = 'none';
    }

    // Top menu hover events
    if ($('.js-top-menu').length) {
        const viewport = new Viewport();

        const _openDropdown = function () {
            // Close all sibling .js-dropdown menus
            for (const dropdown of [...this.parentNode.children])
                if (this !== dropdown && dropdown.classList.contains('js-dropdown'))
                    dropdown.setAttribute('aria-expanded', 'false');

            this.setAttribute('aria-expanded', 'true');

            // Load dropdown async
            if (!this.classList.contains('loaded')) {
                const $dropdownMenu = $(this).children('.dropdown-menu'),
                    url = this.classList.contains('prk-top-menu__item--main')
                        ? '/include/header/top_menu_list_dropdown.php'
                        : '/include/header/top_section_dropdown.php',
                    sectionId = this.dataset.sectionId;

                showLoader($dropdownMenu, 'prk-top-menu-ajax-loader', 1);
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: new URLQueryParams({ 'section_id': sectionId }),
                })
                    .then(response => response.text())
                    .then(data => {
                        hideLoader($dropdownMenu, 'prk-top-menu-ajax-loader');
                        $dropdownMenu.html(data);
                        this.classList.add('loaded');
                    })
                    .catch(reason => {
                        logError(reason);
                        hideLoader($dropdownMenu, 'prk-top-menu-ajax-loader');
                    });
            }
        };

        const _debouncedOpenDropdown = debounce(_openDropdown, 200);

        /**
         * @param {jQueryEvent} event
         */
        const openDropdown = function (event) {
            const { target, pageX } = event;
            const position = viewport.getElementPosition(target);
            if (pageX - position.left > 0.67 * position.width) {
                // In case mouse is moved fast to inner menu
                // and user accidentally hovers another menu
                // (another menu's right third)
                // ignore this as user error.
                // Or if a user holds cursor on the new menu
                // for longer then 200ms, treat as correct user action
                _debouncedOpenDropdown.call(this);
                return;
            }

            _openDropdown.call(this);
        };

        const closeDropdown = function (event) {
            if (this !== event.target && this.contains(event.target)) return;
            this.setAttribute('aria-expanded', 'false');
        };

        const closeAllDropdownsInMenu = function (event) {
            if (this !== event.target && this.contains(event.target)) return;
            for (const dropdown of [...this.querySelectorAll('.js-dropdown')])
                dropdown.setAttribute('aria-expanded', 'false');
        };

        $(document)
            .on('click', '.js-dropdown.lvl-0, .prk-top-menu__item--main .js-dropdown:not(.lvl-1), .prk-top-menu__item--popular .js-dropdown', openDropdown)
            .on('mouseenter', '.prk-top-menu__item--main .js-dropdown.lvl-1', openDropdown, true)
            .on('mouseleave', '.js-dropdown.lvl-0, .prk-top-menu__item--main .js-dropdown:not(.lvl-1), .prk-top-menu__item--popular .js-dropdown', closeDropdown, true)
            .on('mouseleave', '.prk-top-menu__item--main .prk-top-menu.lvl-1', closeAllDropdownsInMenu, true)
            .on('mouseleave', '.prk-top-menu__item--main .js-dropdown.lvl-1', _debouncedOpenDropdown.cancel, true);
    }
}

export const initPrkProductSliders = function () {
    ready(() => {
        const prkProductSliderContainers = [...document.querySelectorAll('.prk-product-slider:not(.is-initializing):not(.is-initialized)')];
        if (!prkProductSliderContainers.length) return;

        prkProductSliderContainers.map(async prkProductSliderContainer => {
            try {
                const slider = new PrkProductSlider(prkProductSliderContainer);
                await slider.ready;
                if (slider.slides.length === 0)
                    prkProductSliderContainer.style.display = 'none';
            }
            catch (error) {
                // Null means nothing to log
                if (null !== error)
                    logError(error);

                // prkProductSliderContainer might have been destroyed, check its presense
                if (prkProductSliderContainer)
                    prkProductSliderContainer.style.display = 'none';
            }
        });
    });
}

/**
 * Adds an image slider for each product
 */
export const initProductImageSliders = function () {
    const initializedCssClass = 'catalog-product-initialized';

    let currentImageIndex = 0;

    /**
     * Creates a promise, which will download the image with urlImage from the server
     * @param {urlImage}
     */
    const downloadImage = function (urlImage) {
        return new Promise(function (resolve, reject) {
            const image = new Image();
            image.onload = function () {
                resolve(urlImage);
            }
            image.onerror = function () {
                reject(new ErrorWithContext('Failed loading image', {
                    extra: { urlImage }
                }));
            }
            image.src = urlImage;
        });
    }

    /**
    * Changes the current image url of a product
    * @this  {imageHtmlElement} The product html image element located inside .catalog-product .img
    * @param {urlImage}
    * @param {promisesToProductImages}
    * @param {idImageInsideProductSlider}
    * @param {event}
    */
    const changeImageUrl = function (urlImage, promisesToProductImages, idImageInsideProductSlider, event) {
        currentImageIndex = idImageInsideProductSlider;
        promisesToProductImages[idImageInsideProductSlider] = promisesToProductImages[idImageInsideProductSlider] || downloadImage(urlImage);
        promisesToProductImages[idImageInsideProductSlider].then(imageSrc => {
            if (idImageInsideProductSlider === currentImageIndex)
                this.src = imageSrc;
        }).catch(() => { });
    }

    /**
     * Changes the current image url of a product to the main one
     * @this  {imageHtmlElement} The product html image element located inside .catalog-product .img
     * @param {promisesToProductImages}
     * @param {event}
     */
    const setMainImage = function (promisesToProductImages, event) {
        currentImageIndex = 0;
        promisesToProductImages[currentImageIndex].then(imageSrc => {
            this.src = imageSrc;
        });
    }

    /**
     * initializes the slider for product images
     * @param {product}
     */
    const initializeSlider = function (event) {
        this.removeEventListener(event.type, initializeSlider);
        this.classList.add(initializedCssClass);

        const linkHtmlElement = this.querySelector('.img');
        const imageHtmlElement = linkHtmlElement.querySelector('img');

        if (imageHtmlElement === null) {
            return;
        }
        const imageUrls = JSON.parse(imageHtmlElement.dataset.gallery);

        if (Array.isArray(imageUrls) && imageUrls.length > 1) {
            const promisesToProductImages = [Promise.resolve(imageUrls[0])];

            // Adding div dynamically
            const imagesAreaDiv = document.createElement('div');
            imagesAreaDiv.className = 'catalog-product__gallery';

            for (let i = 0; i < Math.min(5, imageUrls.length); i++) {
                // Creating internal div for each picture
                const imageAreaToBeShown = document.createElement('div');
                imageAreaToBeShown.className = 'catalog-product__gallery-box';
                imageAreaToBeShown.dataset.id = i;
                imageAreaToBeShown.addEventListener('mouseenter', changeImageUrl.bind(imageHtmlElement,
                    imageUrls[imageAreaToBeShown.dataset.id], promisesToProductImages, imageAreaToBeShown.dataset.id), false);

                imagesAreaDiv.appendChild(imageAreaToBeShown);
            }
            imagesAreaDiv.addEventListener('mouseout', setMainImage.bind(imageHtmlElement, promisesToProductImages), false);

            linkHtmlElement.appendChild(imagesAreaDiv);
        }
    }

    const onCatalogUpdate = function() {
        const products = [...document.querySelectorAll(`.catalog-product:not(.${initializedCssClass})`)];
        for (const product of products) {
            product.addEventListener('mouseenter', initializeSlider);
        }
    }

    onCatalogUpdate();
    document.addEventListener('catalogupdate', onCatalogUpdate);
}

export const initPrkCollapsibles = function () {
    document.addEventListener('click', handleClickOnPrkCollapsible);
}

export function initSearchBox() {
    const searchBox = document.querySelector('.search-line input[name=q]');
    if (!searchBox) return;

    const storage = new RecentSearchQueryRepository();

    const shouldShowRecentSearches = searchBox.dataset['abTestRecentSearches'] === 'B';

    const query = getQueryParam('q');
    searchBox.value = query ? query : '';
    if (query) {
        const q = query.replace(/\s+/g,' ').trim();
        storage.add({ text: q, link: `/search?q=${encodeURI(q)}`, icon: '', isPureSearch: true})
    }

    const searchBoxFocusHandler = () => {
        const queriesMap = new Map();

        $(document).on('click', '.js-autocomplete-link', function (e) {
            e.preventDefault();

            const item = queriesMap.get(this.dataset.item);
            if (!item) {
                return;
            }
            const { icon, link, text, type, isPureSearch } = item;
            if (shouldShowRecentSearches && type !== 'product') {
                storage.add({ text, icon, link, isPureSearch });
            }

            window.location.href = link;
        });

        searchBox.removeEventListener('focus', searchBoxFocusHandler);
        loadAutocomplete().then(() => {
            // Search box autocomplete
            const options = {
                url: function () {
                    return '/include/title_search.php';
                },
                data: shouldShowRecentSearches
                    ? { previousSearches: storage.getRecent(), sections: [], products: [], brands: [], }
                    : undefined,
                getValue: 'text',
                template: {
                    type: 'custom',
                    method: function (value, item) {
                        const key = Math.random().toString();
                        queriesMap.set(key, item);

                        if (item.isPureSearch) {
                            return `
                                <a 
                                    class="js-autocomplete-link eac-item--query"
                                    data-item="${key}" 
                                    href="${item.link}"
                                >
                                      <span class="eac-item__icon">
                                          <svg class="pi pi-search">
                                              <use xlink:href="#search"></use>
                                          </svg>
                                      </span>
                                      <span>${value}</span>
                                </a>`;
                        } else {
                            return `
                                <a
                                    class="js-autocomplete-link"
                                    data-item="${key}" 
                                    href="${item.link}"
                                >
                                      <img alt="" onerror="this.src = '/images/no_photo.png'" src="${item.icon}" />
                                      ${value}
                                </a>`;
                        }
                    }
                },
                categories: [
                    shouldShowRecentSearches
                        ? { listLocation: 'previousSearches', header: 'Искали ранее', maxNumberOfElements: 5 }
                        : undefined,
                    { listLocation: 'sections', header: 'Разделы', maxNumberOfElements: 4 },
                    { listLocation: 'brands', header: 'Бренды', maxNumberOfElements: 3 },
                    { listLocation: 'products', header: 'Товары', maxNumberOfElements: 11 },
                ].filter(Boolean),
                minCharNumber: 2,
                ajaxSettings: {
                    dataType: 'json',
                    method: 'POST',
                    data: {
                        dataType: 'json',
                        iblock_id: 12
                    },
                    dataFilter: (data) => {
                        if (!shouldShowRecentSearches) {
                            return data;
                        }

                        try {
                            data = JSON.parse(data);
                        } catch (e) {
                            return data;
                        }

                        const previousSearches = storage.search(searchBox.value);
                        const urls = previousSearches.map((x) => x.link);
                        return JSON.stringify({
                            ...data,
                            previousSearches,
                            products: data.products
                                .filter((x) => !urls.includes(x.link))
                                .map((x) => ({...x, type: 'product' })),
                            sections: data.sections
                                .filter((x) => !urls.includes(x.link))
                                .map((x) => ({...x, type: 'section' })),
                            brands: data.brands
                                .filter((x) => !urls.includes(x.link))
                                .map((x) => ({...x, type: 'brand' })),
                        });
                    }
                },
                preparePostData: function (data) {
                    data.q = $(searchBox).val();
                    return data;
                },
                list: {
                    maxNumberOfElements: 16,
                    onShowListEvent: () => {
                        const container = document.querySelector('.search-line');
                        if (container) {
                            container.classList.add('search-line--no-border-bottom-radius');
                        }
                    },
                    onHideListEvent: () => {
                        const container = document.querySelector('.search-line');
                        if (container) {
                            container.classList.remove('search-line--no-border-bottom-radius');
                        }
                    },
                },
                requestDelay: 300,
                theme: 'green-light',
                adjustWidth: false
            };

            jQuery(searchBox).easyAutocomplete(options);
            searchBox.focus();
        }).catch(logError);
    }
    searchBox.addEventListener('focus', searchBoxFocusHandler);
}

export function initComparing() {
    if ($('.js-add-to-comparing').length) {
        import('/js/common/comparing').then((comparing) => {
            comparing.addComparingHandlers();
        });
    }
}

const countryCodes = {
    russia: '7',
    belorussia: '375'
}
const phoneFormats = {
    russia: 'h000000000',
    belorussia: '000000000',
}
export const phoneMasks = {
    countryCodes,
    withoutCountryCode: phoneFormats,
    withCountryCode: {
        russia: `+{${countryCodes.russia}}${phoneFormats.russia}`,
        belorussia: `+{${countryCodes.belorussia}}${phoneFormats.belorussia}`,
    }
}

/**
 * Masks phone inputs
 * @param {string} selector
 * @param {string} mask
 * @param {{ [key: string]: definition }} definitions
 * @returns {Promise<void>}
 */
export async function initMaskedInput(selector, mask, definitions) {
    mask = mask || phoneMasks.withCountryCode.russia;
    definitions = definitions || {
        h: /[9]/
    };

    /**
     * Using spread operator to turn NodeListOf<Element> (which is pseudo-array
     * without capabilities of using for of cycle) into Element[].
     * @var {HTMLInputElement[]}
     */
    const phoneInputsToMask = [...document.querySelectorAll(selector)];
    if (phoneInputsToMask.length) {
        const IMask = await loadIMaskPhone();
        if (!IMask) return; // In case loading the module failed

        // Hash of IMask instances, is used to destroy/reinit
        window._maskedInputs = window._maskedInputs || {};
        for (const phoneInput of phoneInputsToMask) {
            // UID to identify IMask instance
            let inputUID = phoneInput.dataset.maskedInputUid;

            if (inputUID && window._maskedInputs[inputUID]) {
                // If input already has a mask attached, detach before proceeding
                window._maskedInputs[inputUID].destroy();
                window._maskedInputs[inputUID] = null;
            } else {
                inputUID = `${performance.now()}${Math.random()}`;
                phoneInput.dataset.maskedInputUid = inputUID;
            }

            // 'lazy: false' option below makes pattern to be shown all the time
            window._maskedInputs[inputUID] = new IMask(phoneInput, { mask, definitions, lazy: false });
        }
    }
}

export const initPaymentOnCheckoutConfirmPage = async function() {
    const paymentContainer = document.querySelector('.js-confirm-container .js-payment-container');
    if (!paymentContainer)
        return;

    const countdownElement = paymentContainer.querySelector('.js-countdown');
    let countdownSeconds = Number(countdownElement?.innerText);

    const paymentLinkElement = paymentContainer.querySelector('.js-payment-form-link');
    const paymentUrl = paymentLinkElement?.href;
    const { paymasterPaymentResultUrl } = paymentContainer.dataset;

    if (paymentUrl) {
        // Counts 3, 2, 1 letting user see order details and js code to get ready
        const counterPromise = new Promise(resolve => {
            let countdownIntervalHandle = setInterval(() => {
                countdownElement.innerText = --countdownSeconds;
                if (countdownSeconds < 1) {
                    clearInterval(countdownIntervalHandle);
                    resolve();
                }
            }, 1000);
        });

        // Load Paymaster payment widget in parallel, if should
        const loadPaymasterWidgetPromise = paymasterPaymentResultUrl
            ? loadPaymaster()
                .then(Paymaster => new Paymaster())
                .catch(error => {
                    // No need to log here,
                    // just let the promise
                    // resolve
                })
            : Promise.resolve();

        await Promise.all([
            counterPromise,
            loadPaymasterWidgetPromise,
        ]);

        if (!paymasterPaymentResultUrl) {
            window.location.href = paymentUrl;
            return;
        }

        try {
            // Pay with Paymaster payment widget if available
            const paymaster = await loadPaymasterWidgetPromise;
            await paymaster.payByUrl(paymentUrl);
            window.location.href = paymasterPaymentResultUrl;
            return;
        } catch (error) {
            // When failed to load paymaster widget,
            // log error and fall back to the default scenario
            logError(error);
        }

        window.location.href = paymentUrl;
    }
}

export const initJSLinkHandler = () => {
    let isDragging = false;

    const moveHandler = function() {
        isDragging = true;
    };

    $(document).on('mousedown', function() {
        $(document).on('mousemove', moveHandler);
    });

    $(document).on('mouseup', function() {
        $(document).off('mousemove', moveHandler);

        if (!isDragging) return;

        setTimeout(function() {
            isDragging = false;
        });
    });

    const handledMouseButtons = [0, 1];
    $(document).on('mouseup', '.js-link', function (e) {
        if (!handledMouseButtons.includes(e.button)) {
            return;
        }

        // replacing 'click' with 'mouseup' to support the middle button
        if (isDragging) return;

        const href = this.dataset.href ?? this.getAttribute('href');
        if (!href)
            return;

        const shallOpenInNewTab = (this.dataset.target ?? this.getAttribute('target')) === '_blank'
            || e.button === 1
            || e.ctrlKey
            || e.metaKey;
        if (shallOpenInNewTab)
            window.open(href, '_blank');
        else
            window.location.href = href;
    });
}

export const initFixedHeaderArea = function() {
    const wrap = document.querySelector('.fixed-header-wrap'),
        fixed = document.querySelector('.js-fixed-area');

    if (fixed === null) return;

    let {
        paddingTop,
        paddingBottom,
        height
    } = getComputedStyle(wrap);

    paddingTop = parseFloat(paddingTop) || 0;
    paddingBottom = parseFloat(paddingBottom) || 0;

    wrap.style.height = height;

    const viewport = new Viewport(document.body);

    let isFixed = false,
        isShadowed = false;

    const handleScrollEvent = function() {
        const { top } = viewport.getElementPosition(wrap);
        // need to smooth transition
        if (top + paddingTop < 0) {
            if (!isFixed) {
                fixed.classList.add('fixed');
                isFixed = true;
            }
        } else if (isFixed) {
            fixed.classList.remove('fixed');
            isFixed = false;
        }
        // need to show shadow
        if (top + paddingTop + paddingBottom < 0) {
            if (!isShadowed) {
                fixed.classList.add('shadowed');
                isShadowed = true;
            }
        } else if (isShadowed) {
            fixed.classList.remove('shadowed');
            isShadowed = false;
        }
    }

    window.addEventListener('scroll', handleScrollEvent);
    window.addEventListener('resize', throttle(handleScrollEvent, 100));

    handleScrollEvent();
}

export const renderProductUnavailableButtons = function() {
    const replace = () => {
        [...document.querySelectorAll('.js-product-unavailable-for-order-button')]
            .forEach(place => {
                const button = document.createElement('button');
                button.className = 'na btn js-link';
                button.dataset.href = place.dataset.href + '#similar-products';
                button.innerHTML = `Выбрать аналог`;

                place.parentNode.replaceChild(button, place);
            });
    }
    replace();
    document.addEventListener('catalogupdate', replace);
}

/**
 * Returns padding that is needed when scrolling to element
 * to prevent that element be covered by either 
 * prk or bitrix floating top menus
 * @return {number}
 */
export const getViewportPaddingTop = function() {
    const oneRem = parseInt(getComputedStyle(document.body)['font-size']) || 14;
    const fixedHeaderHeight = document.querySelector('.js-fixed-area')?.offsetHeight ?? 0;
    const bitrixPanelHeight = document.querySelector('.bx-panel-fixed')?.offsetHeight ?? 0;

    return Math.max(bitrixPanelHeight, fixedHeaderHeight) + oneRem;
}

/**
 * Helper function that implements common logic
 * specific to PRK when scrolling to an element
 * @param {HTMLElement} element
 * @returns {void}
 */
export const scrollToElement = function(element) {
    if (!element) return;

    // Do not scroll to invisible elements
    if ('none' === getComputedStyle(element)['display'])
        return;

    const viewport = new Viewport();
    viewport.scrollToElement(element, getViewportPaddingTop())
        .catch(() => null);
}

export const initLoadMore = () => {
    const init = (initialPaginationState) => {
        const container = document.querySelector('.js-productsContainer');
        if (!container) {
            return;
        }

        const getPaginationState = (button) => {
            if (!button) {
                return null;
            }

            return {
                firstRecord: button.dataset.firstRecord ?? 1,
                lastRecord: button.dataset.lastRecord ?? 1,
                totalRecords: button.dataset.totalRecords ?? 1,
            };
        }

        const initializeButton = (button, initialPaginationState) => {
            if (!button || button.dataset.initialized) {
                return;
            }

            button.dataset.initialized = true;

            button.addEventListener('click', () => {
                showLoader();

                fetch(`${button.dataset.nextPage}&only_products=true`)
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data || !data.html) {
                            throw new Error('Content has not been sent');
                        }

                        return data.html;
                    })
                    .then((content) => {
                        const template = document.createElement('div');
                        template.innerHTML = content.trim();

                        return  {
                            elements: template.querySelectorAll('.products-container-item, .pagination'),
                            paginationState: getPaginationState(template.querySelector('.pagination__load-more-button'))
                        };
                    })
                    .then(({ elements, paginationState }) => {
                        const previousPagination = container.querySelector('.pagination');
                        if (previousPagination) {
                            previousPagination.remove();
                        }

                        container.append(...elements);

                        const caption = container.querySelector('.pagination__caption');
                        if (caption) {
                            const firstRecord = initialPaginationState?.firstRecord ?? 1;
                            const lastRecord = paginationState?.lastRecord ?? (initialPaginationState?.totalRecords ?? 1);
                            const totalRecords = paginationState?.totalRecords ?? (initialPaginationState?.totalRecords ?? 1);

                            caption.innerText = `Товары ${firstRecord} - ${lastRecord} из ${totalRecords}`;
                        }
                    })
                    .then(() => {
                        document.dispatchEvent(new CustomEvent('catalogupdate', {
                            detail: {
                                pagination: initialPaginationState,
                            }
                        }));
                        initializeButton(container.querySelector('.pagination__load-more-button'), initialPaginationState);
                    })
                    .catch(() => {
                        showMessage('Не удалось загрузить товары.');
                    })
                    .finally(() => hideLoader());
            }, { once: true });
        }

        const btn = container.querySelector('.pagination__load-more-button');

        initializeButton(btn, initialPaginationState ?? getPaginationState(btn));
    }

    document.addEventListener('catalogupdate', (e) => {
        init(e.detail ? e.detail.pagination : null);
    });

    init();
}

export const initViewToggles = () => {
    document.addEventListener('catalogupdate', () => {
        const listViewToggle = document.querySelector('.js-list-view-toggle');
        if (listViewToggle) {
            const query = new URLQueryParams(window.location.search);
            query.set('view', 'list');
            listViewToggle.href = `${window.location.pathname}?${query.toString()}`;
        }

        const cellViewToggle = document.querySelector('.js-cell-view-toggle');
        if (cellViewToggle) {
            const query = new URLQueryParams(window.location.search);
            query.set('view', 'cell');
            cellViewToggle.href = `${window.location.pathname}?${query.toString()}`;
        }
    });
}